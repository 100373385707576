import React from 'react'

import './VacancyPromo.scss';

const VacancyPromo = () => {
  return (
    <div className="vacancy-promo__wrapper">
      <img src={require("./../../../assets/img/vacancy-promo.png")} alt="" className="vacancy-promo__img"/>
      <div className="vacancy-promo__title">Вакансии</div>
    </div>
  )
}

export default VacancyPromo
