import { useEffect } from "react";

export const useOutsideClick = (ref, isOpen, cb) => {
  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      cb(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("click", handleClick);
    }
    return () => document.removeEventListener("click", handleClick);
  }, [isOpen]);
};
