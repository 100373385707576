import React from 'react'
import VacancyItem from './VacancyItem';

import './VacancyList.scss';

const VacancyList = ({dataVacancy, setIsOpenModal, modalEl, setVacancyId}) => {
  return (
    <ul className="vacancy-list">
      {
        dataVacancy.map(el => (
          <VacancyItem setVacancyId={setVacancyId} modalEl={modalEl} setIsOpenModal={setIsOpenModal} key={el.id} dataVacancyItem={el}/>
        ))
      }


    </ul>
  )
}

export default VacancyList
