import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import { initNav, isMobile } from "../../../constants";

import "./News.scss";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import { useWidthContext } from "../../../widthContext";
import { Link } from "react-router-dom";
import {scrollToTop} from "../../../pages/NewsDetail";

const News = ({ data }) => {
  const { isTablet } = useWidthContext();

  if (isTablet) {
    SwiperCore.use([Navigation]);
  }

  return (
    <div className="events">
      <h2 className="events__title">Новости</h2>

      <Swiper
        className="new-product__slider"
        spaceBetween={isMobile ? 10 : 20}
        onInit={(swiper) => (data.length !== 0 ? swiper.update() : "")}
        navigation={isMobile}
        onSwiper={(swiper) => (isMobile ? initNav(swiper) : "")}
        onTouchMove={(swiper) => (swiper.el.style.transform = "scale(0.93)")}
        onTouchEnd={(swiper) => (swiper.el.style.transform = "scale(1)")}
        slidesPerView={"auto"}
      >
        {data.map((el, index) => (
          <SwiperSlide
            key={`${el._id}__${index}`}
            style={{ width: "auto", marginRight: isMobile ? 10 : 20 }}
            onClick={() => {
                scrollToTop()
            }}
          >
            <Link
              to={`/news/${el._id}`}
              className="new-product__item news__item"
            >
              <div className="new-product__item--wrap-img">
                <img
                  src={el.previewImage}
                  alt=""
                  className="new-product__item-img"
                />
                <span className="new-product__item-link">Подробнее</span>
              </div>
              <h5 className="news__title">{el.title}</h5>
            </Link>

            <div
              className=""
              dangerouslySetInnerHTML={{ __html: el.previewText }}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default News;
