import React from 'react'

const TermsItem = ({ title, desc }) => {
  return (
    <div className="terms__item">
      <h5 className="terms__item-title">{title}</h5>
      <p className="terms__item-desc" dangerouslySetInnerHTML={{__html: desc}}/>
    </div>  
  )
}

export default TermsItem
