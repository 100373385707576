import React, { useEffect, useState, useContext } from "react";

import Header from "../components/Header/Header";
import DetailCardPromo from "./../components/DetailCard/DetailCardPromo/DetailCardPromo";
import DetailItems from "./../components/DetailCollection/DetailItems";
import CircleToTop from "./../components/CircleToTop/CircleToTop";
import Footer from "../components/Footer/Footer";
import { collectionApi } from "./../api/api";
import Preloader from "../components/Preloader/Preloader";
import BreadCrumbs from "../components/BreadCrumbs/BreadCrumbs";
import BreadCrumbsItem from "../components/BreadCrumbs/BreadCrumbsItem";
import Helmet from "react-helmet";
import { useWidthContext } from "../widthContext";
import { Redirect } from "react-router-dom";
import { CartContext } from "../context";
import { setCollectionCardСoordinates } from "../reducers/cartReducer";

const DetailCollection = ({ match }) => {
  const [dataItem, setDataItem] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);
  const [isError, setIsError] = useState(false);
  const [state, dispatch] = useContext(CartContext);
  const { isMobile } = useWidthContext();

  useEffect(() => {
    if (!isLoaded) {
      document.body.style.overflow = "hidden";
    }
    collectionApi
      .getCollectionDetailItem(match.params.itemId)
      .then((data) => {
        setDataItem(data);
        setIsLoaded(true);
      })
      .catch(() => setIsError(true));
  }, []);

  useEffect(() => {
    if (isLoaded) {
      document.body.style.overflow = "";

      window.scrollTo({
        top: state.collectionCardСoordinates
          ? state.collectionCardСoordinates
          : 0,
      });

      dispatch(setCollectionCardСoordinates(0));
    }
  }, [isLoaded]);

  return (
    <>
      {isError && <Redirect to="/404" />}
      <Helmet>
        <title>{dataItem.title}</title>
        <meta name="description" content="Nested component" />
      </Helmet>

      <Preloader isLoaded={isLoaded} />
      <section className="goods-card-promo">
        <div className="container">
          <div className="header__wrapper">
            <Header
              color={isMobile ? "black" : "white"}
              isBreadCrumbs={true}
              isBlackPromo={true}
            >
              <BreadCrumbs>
                <BreadCrumbsItem title={"Коллекции"} path={"/collection"} />
                <BreadCrumbsItem
                  title={dataItem.type}
                  path={`/collection/${dataItem.sectionId}`}
                />
                <BreadCrumbsItem
                  title={dataItem.title}
                  path={match.url}
                  isActive={true}
                />
              </BreadCrumbs>
            </Header>
          </div>
          <DetailCardPromo dataDetailPrev={dataItem} />
        </div>
      </section>

      <div className="container">
        <DetailItems dataDetailItems={dataItem} />
      </div>

      <CircleToTop />

      <Footer />
    </>
  );
};
export default DetailCollection;
