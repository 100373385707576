import React, { useEffect, useRef } from "react";
import { useOutsideClick } from "../../hooks/useOutsideClick";
import "./ModalPicture.scss";

const ModalPicture = ({
  setIsPictureModalOpen,
  isPictureModalOpen,
  picture,
  isExample,
}) => {
  useEffect(() => {
    if (isPictureModalOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [isPictureModalOpen]);

  const ref = useRef(null);

  useOutsideClick(ref, isPictureModalOpen, setIsPictureModalOpen);

  return (
    <div className={`modal-picture ${isPictureModalOpen ? "active" : ""}`}>
      <div
        ref={ref}
        className={` ${
          isExample ? "modal-picture__inner-example" : "modal-picture__inner"
        } `}
      >
        <button
          className="modal-picture__close"
          onClick={() => setIsPictureModalOpen(false)}
          type="button"
        ></button>
        <img className="modal-picture__picture" src={picture} />
      </div>
    </div>
  );
};

export default ModalPicture;
