import React from 'react'

import './BreadCrumbs.scss';

const BreadCrumbs = ({children}) => {
  return (
    <>
      <div className="bread-crumbs">
        <div className="bread-crumbs__list">
          {children}
        </div>
      </div>
    </>
  )
}

export default BreadCrumbs
