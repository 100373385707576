import React from 'react';
import './BtnShare.scss';
import {
  ViberShareButton,
  FacebookShareButton,
  VKShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  FacebookIcon,
  ViberIcon,
  VKIcon,
  TelegramIcon,
  WhatsappIcon,
} from "react-share";

export const BtnShare = () => {
  return (
    <div className="button__share">
      <button
        type="button"
        className="button__item"
      >Поделиться
        <svg className="button__item-svg" width="19" height="21" viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M15.2499 14.4098C14.5215 14.4098 13.8699 14.6973 13.3716 15.1478L6.5387 11.1707C6.58662 10.9503 6.62495 10.7299 6.62495 10.4999C6.62495 10.2699 6.58662 10.0495 6.5387 9.82905L13.2949 5.89033C13.8124 6.3695 14.4928 6.66658 15.2499 6.66658C16.8407 6.66658 18.1248 5.38242 18.1248 3.7916C18.1248 2.20078 16.8407 0.916626 15.2499 0.916626C13.6591 0.916626 12.3749 2.20078 12.3749 3.7916C12.3749 4.0216 12.4132 4.24201 12.4611 4.46243L5.70496 8.40114C5.18746 7.92198 4.50705 7.6249 3.74997 7.6249C2.15916 7.6249 0.875 8.90906 0.875 10.4999C0.875 12.0907 2.15916 13.3749 3.74997 13.3749C4.50705 13.3749 5.18746 13.0778 5.70496 12.5986L12.5282 16.5852C12.4803 16.7865 12.4516 16.9973 12.4516 17.2082C12.4516 18.7511 13.707 20.0065 15.2499 20.0065C16.7928 20.0065 18.0482 18.7511 18.0482 17.2082C18.0482 15.6652 16.7928 14.4098 15.2499 14.4098Z" fill="black" />
        </svg>
      </button>
      <div
        className="button__share-list"
      >
        {/* <FacebookShareButton
          url={window.location.href}
          className="facebook button__share-item"
        >
          <div className="button__share-item">
            <FacebookIcon size={56} round />
            Facebook
          </div>
        </FacebookShareButton> */}
        <VKShareButton
          url={window.location.href}
          className="vk button__share-item"
        >
          <div className="button__share-item">
            <VKIcon size={56} round />
            VK
          </div>
        </VKShareButton>
        <ViberShareButton
          url={window.location.href}
          className="viber button__share-item"
        >
           <div className="button__share-item">
            <ViberIcon size={56} round />
            Viber
          </div>
        </ViberShareButton>
        <WhatsappShareButton
          url={window.location.href}
          className="whatsapp button__share-item"
        >
           <div className="button__share-item">
            <WhatsappIcon size={56} round />
            Whatsapp
          </div>
        </WhatsappShareButton>
        <TelegramShareButton
          url={window.location.href}
          className="telegram button__share-item"
        >
           <div className="button__share-item">
            <TelegramIcon size={56} round />
            Telegram
          </div>
        </TelegramShareButton>
      </div>
    </div>
  )
}