import React, {useEffect, useState} from 'react'
import {Swiper, SwiperSlide} from 'swiper/react';
import SwiperCore, {Navigation} from 'swiper';
import {isMobile, arrow, initNav} from '../../../constants';

import './Collection.scss'
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import {Link} from "react-router-dom";
import {collectionApi} from "../../../api/api";


const Collection = ({collectionCategories}) => {
  const [activeSliderId, setActiveSliderId] = useState(null);
  const [swiperActive, setSwiperActive] = useState(null);
  const [collectionActiveData, setCollectionActiveData] = useState([]);

  useEffect(() => {
    if (!activeSliderId && collectionCategories.length > 0) {
      setActiveSliderId(collectionCategories[0]?._id);
    }
  }, [collectionCategories]);


  useEffect(() => {
    if (!!activeSliderId) {
      collectionApi.getCollectionItems(activeSliderId)
        .then(data => {
          setCollectionActiveData(data);
          if (!!swiperActive) {
            swiperActive.update();
            swiperActive.slideTo(0)
          }
        });
    }
  }, [activeSliderId]);

  if (isMobile) {
    SwiperCore.use([Navigation]);
  }

  return (
    <div className="collection">
      <div className="container">
        <h2 className="collection__title">Коллекции</h2>
        <h2 className="collection__bg-title">COLLECTION</h2>
        <div className="collection__list">
          <div className="collection__list-btn ">
            {
              collectionCategories.length > 0 && (
                collectionCategories.map((el, index) => (
                  <button
                    key={el._id}
                    onClick={() => {
                      if (!!el.title) {
                        setActiveSliderId(el._id);
                      }
                      // swiperActive.slideTo(index);
                    }
                    }
                    className={`collection__btn ${activeSliderId === el._id ? 'active' : ''}`}
                  >{el.title}</button>
                ))
              )
            }
          </div>
        </div>

        {
          collectionActiveData.length > 0 && (
            <Swiper
              className="collection__slider"
              spaceBetween={isMobile ? 10 : 120}
              onInit={(swiper) => {
                collectionActiveData.length !== 0 && swiper.update();
                setSwiperActive(swiper);
              }
              }
              onSwiper={(swiper) => isMobile ? initNav(swiper) : ''}
              // onSlideChange={(swiper) => setActiveSliderName(dataCollection[swiper.activeIndex].title)}
              onTouchMove={(swiper) => swiper.el.style.transform = 'scale(0.93)'}
              onTouchEnd={(swiper) => swiper.el.style.transform = 'scale(1)'}
              navigation={isMobile ? true : false}
            >

              {
                collectionActiveData.map(el => (
                  <SwiperSlide key={el._id}>
                    <Link to={`/detailCollection/${el._id}/${el.title}`} className="collection__item">
                      <img src={el.image} alt={el.title} className="collection__item-img"/>
                      <h2 className="collection__item-title">{el.title}</h2>
                    </Link>
                  </SwiperSlide>
                ))
              }

            </Swiper>
          )
        }
      </div>
    </div>
  )
}

export default Collection
