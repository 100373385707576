import React, {useContext} from 'react';

import './DiscountModels.scss';
import {Link} from "react-router-dom";
import {CartContext} from "../../context";
import {setDiscountCardCoordinates} from "../../reducers/cartReducer";

const DiscountModels = ({ dataDiscountItems }) => {
  const [state, dispatch] = useContext(CartContext);

  return (
    <div className="discount-model__list">
      <h4 className="discount-model__list-title">{dataDiscountItems?.title}</h4>
      <div className="discount-model__list-wrapper">

        {
          dataDiscountItems?.items?.length > 0 && (
            dataDiscountItems?.items?.map(el => (
              <Link
                onClick={() => {
                  dispatch(setDiscountCardCoordinates(window.pageYOffset))
                }}
                to={`/discount-detail/${el._id}/${el.title}`}
                key={el._id}
                className="discount-model__item">
                <img src={el.image} alt={el.title} className="discount-model__item-img"/>
                <div className="discount-model__text">
                  <div className="discount-model__item-name">{el.title}</div>
                  <div className="discount-model__price">
                    <span className="price-sale">{el.oldPrice}</span>
                    <div className="price-no-sale">{el.price}</div>
                  </div>
                </div>
              </Link>
            ))
          )
        }


      </div>
    </div>
  )
}

export default DiscountModels
