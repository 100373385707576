import React, {useEffect} from 'react'
import CartOrders from './CartOrders/CartOrders';
import CartForm from './CartForm/CartForm';

import './CartRegisterOrder.scss';

const CartRegisterOrder = ({updateStage, setIsFinallyStage}) => {

  useEffect(() => {
    window.scrollTo({
      top: 0,
    })
  }, [])

  return (
    <div className='order'>
      <CartOrders/>
      <CartForm updateStage={updateStage} setIsFinallyStage={setIsFinallyStage}/>
    </div>
  )
}

export default CartRegisterOrder
