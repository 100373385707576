import React, { useContext } from "react";
import { Link } from "react-router-dom"; 

import "./ProductsModel.scss";
import { CartContext } from "../../context";
import { setProductionCardСoordinates } from "../../reducers/cartReducer";

const ProductsModel = ({ dataProductsItems }) => {
  const [state, dispatch] = useContext(CartContext);

  return (
    <div className="products-model__list">
      <h4 className="products-model__list-title">
        {!!dataProductsItems.title ? dataProductsItems.title : ""}
      </h4>
      <div className="products-model__list-wrapper">
        {!!dataProductsItems.items &&
          dataProductsItems.items.length > 0 &&
          dataProductsItems.items.map((el) => (
            <Link
              onClick={() => {
                dispatch(setProductionCardСoordinates(window.pageYOffset));
              }}
              key={el._id}
              to={`/detailCard/${el._id}/${!!el._code ? el._code : el.title}`}
              className="products-model__item"
            >
              <img
                src={el.image}
                alt={el.title}
                className="products-model__item-img"
              />
              <h6 className="products-model__item-subtitle">
                {dataProductsItems?.title}
              </h6>
              <div className="products-model__item-name">{el.title}</div>
            </Link>
          ))}
      </div>
    </div>
  );
};

export default ProductsModel;
