import React, {useContext, useEffect, useState} from 'react'
import {Field, Formik} from 'formik';
import './CartForm.scss'
import {validateFormCart} from "../../../../Functions";
import {CartContext} from "../../../../context";
import {addFormCart} from "../../../../reducers/cartReducer";
import {cartApi} from "../../../../api/api";
import {useWidthContext} from "../../../../widthContext";


const CartForm = ({updateStage, setIsFinallyStage}) => {
  const [state, dispatch] = useContext(CartContext);
  const [face, setFace] = useState(0);
  // const [isOpen, setIsOpen] = useState(false);
  const [selectCity, setSelectCity] = useState('');
  const [citiesAll, setCitiesAll] = useState([]);
  const {isMobile} = useWidthContext();


  const faces = ['Физическое лицо', 'Юридическое лицо'];


  useEffect(() => {
    cartApi.getLocation()
      .then(data => setCitiesAll(data));
  }, []);

  useEffect(() => {
    if (citiesAll.length > 0) {
      setSelectCity(citiesAll[0])
    }
  }, [citiesAll]);

  return (
    <Formik
      initialValues={{
        name: '',
        phone: '',
        email: '',
        politic: false,
        address: '',
        comment: '',
        company: '',
      }}
      validate={values => validateFormCart(values, face)}
      onSubmit={(values, {setSubmitting}) => {
        dispatch(addFormCart(values, null, 2));
        setSubmitting(false);
      }}
    >
      {({
          values,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          isValid,
          dirty,
          /* and other goodies */
        }) => (
        <form
          className="order__form"
          onSubmit={handleSubmit}
        >

          <div className="form__person-wrapper">
            {
              isMobile ? (
                // <CartFormDropdown
                //   dataDropdown={faces}
                //   setActiveItem={setFace}
                // />
                <button
                  type="button"
                  className={`form__person active`}
                >Ваши данные</button>
              ) : (
                <div className="form__persons">
                  {/*{*/}
                  {/*  faces.map((el, index) => (*/}
                  {/*    <button*/}
                  {/*      key={`${el}_${index}`}*/}
                  {/*      type="button"*/}
                  {/*      className={`form__person ${face === index ? 'active' : ''}`}*/}
                  {/*      onClick={() => setFace(index)}*/}
                  {/*    >{el}</button>*/}
                  {/*  ))*/}
                  {/*}*/}
                  <button
                    type="button"
                    className={`form__person active`}
                  >Ваши данные
                  </button>
                </div>
              )
            }


            {
              face === 0 ? (
                <div className="form__data">
                  {/*<h5 className="form__data-title">Ваши данные</h5>*/}
                  <div className="form__data-item">
                    <input
                      type="text"
                      className={`form__data-input ${errors.name}`}
                      name="name"
                      id="data-name"
                      placeholder="Имя"
                      onChange={handleChange}
                      // onBlur={handleBlur}
                      value={values.name}
                    />
                    <label htmlFor="data-name" className="form__data-label">Ваше имя</label>
                  </div>

                  <div className="form__data-item">
                    <input
                      type="text"
                      name="phone"
                      className={`form__data-input ${errors.phone}`}
                      id="data-phone"
                      placeholder="Телефон"
                      onChange={handleChange}
                      // onBlur={handleBlur}
                      value={values.phone}
                    />
                    <label htmlFor="data-phone" className="form__data-label">+7 (900) 000 00-00</label>
                  </div>

                  <div className="form__data-item">
                    <input
                      type="text"
                      className={`form__data-input ${errors.email}`}
                      id="data-email"
                      name="email"
                      placeholder="Почта для связи с вами"
                      onChange={handleChange}
                      // onBlur={handleBlur}
                      value={values.email}
                    />
                    <label htmlFor="data-email" className="form__data-label">E-mail</label>
                  </div>
                  <div className="form__data-politic">
                    <Field type="checkbox" name="politic" className="form__politic-check" id="data-politic"/>
                    <label htmlFor="data-politic" className={`form__politic-title ${errors.politic}`}>
                      <a
                        href="https://evanty.ru/upload/%D0%9F%D0%BE%D0%BB%D0%B8%D1%82%D0%B8%D0%BA%D0%B0_%D0%BE%D0%BF%D0%B5%D1%80%D0%B0%D1%82%D0%BE%D1%80%D0%B0_%D0%B2_%D0%BE%D1%82%D0%BD%D0%BE%D1%88%D0%B5%D0%BD%D0%B8%D0%B8_%D0%BE%D0%B1%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D0%BA%D0%B8_%D0%BF%D0%B5%D1%80%D1%81%D0%B4%D0%B0%D0%BD%D0%BD%D1%8B%D1%85.pdf"
                        download
                      >Даю согласие на обработку моих персональных данных в соответствии с 152-ФЗ</a>
                    </label>
                  </div>
                </div>
              ) : (
                <>
                </>
                // <div className="form__data">
                //   <h5 className="form__data-title">Ваши данные</h5>
                //   <div className="form__data-item">
                //     <input
                //       type="text"
                //       className={`form__data-input ${errors.name}`}
                //       name="name"
                //       id="data-name"
                //       placeholder="Имя"
                //       onChange={handleChange}
                //       onBlur={handleBlur}
                //       value={values.name}
                //     />
                //     <label htmlFor="data-name" className="form__data-label">Ваше имя</label>
                //   </div>
                //   <div className="form__data-item">
                //     <input
                //       type="text"
                //       className={`form__data-input ${errors.company}`}
                //       name="company"
                //       id="data-company"
                //       placeholder="Организация"
                //       onChange={handleChange}
                //       onBlur={handleBlur}
                //       value={values.company}
                //     />
                //     <label htmlFor="data-company" className="form__data-label">Имя организации</label>
                //   </div>
                //   <div className="form__data-item">
                //     <input
                //       type="text"
                //       name="phone"
                //       className={`form__data-input ${errors.phone}`}
                //       id="data-phone"
                //       placeholder="Телефон"
                //       onChange={handleChange}
                //       onBlur={handleBlur}
                //       value={values.phone}
                //     />
                //     <label htmlFor="data-phone" className="form__data-label">+7 (900) 000 00-00</label>
                //   </div>
                //
                //   <div className="form__data-item">
                //     <input
                //       type="text"
                //       className={`form__data-input ${errors.email}`}
                //       id="data-email"
                //       name="email"
                //       placeholder="Почта для связи с вами"
                //       onChange={handleChange}
                //       onBlur={handleBlur}
                //       value={values.email}
                //     />
                //     <label htmlFor="data-email" className="form__data-label">E-mail</label>
                //   </div>
                //   <div className="form__data-politic">
                //     <Field type="checkbox" name="politic" className="form__politic-check" id="data-politic"/>
                //     <label htmlFor="data-politic" className="form__politic-title">Даю согласие на обработку моих персональных данных в соответствии с 152-ФЗ</label>
                //   </div>
                // </div>
              )
            }
          </div>


          <div className="form__delivery-wrapper">
            <div className="form__delivery-title active">Ваш город</div>


            <div className="form__delivery-content">

              <div className="form__data-item">
                <input
                  type="text"
                  className={`form__data-input ${errors.address}`}
                  id="data-email"
                  name="address"
                  placeholder="Город"
                  onChange={handleChange}
                  // onBlur={handleBlur}
                  value={values.address}
                />
              </div>

              {/*{*/}
              {/*  citiesAll.length > 0 && (*/}
              {/*    <>*/}
              {/*      <Select*/}
              {/*        className="form__delivery-select"*/}
              {/*        options={citiesAll}*/}
              {/*        defaultValue={citiesAll[0]}*/}
              {/*        onChange={setSelectCity}*/}
              {/*        isSearchable={true}*/}
              {/*        classNamePrefix="react-select"*/}
              {/*      />*/}
              {/*      <input*/}
              {/*        type="text"*/}
              {/*        name="address"*/}
              {/*        onChange={handleChange}*/}
              {/*        onBlur={handleBlur}*/}
              {/*        value={values.address = !!selectCity ? selectCity.value : ''}*/}
              {/*        style={{display: 'none'}}*/}
              {/*      />*/}
              {/*    </>*/}
              {/*  )*/}
              {/*}*/}

              {/*<div className={`form__delivery-select ${isOpen ? 'active' : ''}`}>*/}
              {/*  <button*/}
              {/*    type="button"*/}
              {/*    className="form__delivery-city--btn"*/}
              {/*    onClick={() => setIsOpen(!isOpen)}*/}
              {/*  />*/}
              {/*  <span className="form__delivery-city">{selectCity}</span>*/}
              {/*  <input*/}
              {/*    type="text"*/}
              {/*    name="address"*/}
              {/*    onChange={handleChange}*/}
              {/*    onBlur={handleBlur}*/}
              {/*    value={values.address = selectCity}*/}
              {/*    style={{display: 'none'}}*/}
              {/*  />*/}

              {/*  <div*/}
              {/*    className={`form__delivery-dropdown ${isOpen ? 'active' : ''}`}*/}
              {/*  >*/}
              {/*    {*/}
              {/*      cities.map((el, index) => (*/}
              {/*        <div*/}
              {/*          key={`${el}_${index}`}*/}
              {/*          className="form__delivery-option"*/}
              {/*          onClick={() => {*/}
              {/*            setSelectCity(el);*/}
              {/*            setIsOpen(false);*/}
              {/*          }}*/}
              {/*        >{el}</div>*/}
              {/*      ))*/}
              {/*    }*/}
              {/*  </div>*/}
              {/*</div>*/}

            </div>

          </div>

          <div className="form__comments-wrapper">
          <textarea
            cols="30"
            rows="10"
            className="form__comments-text"
            placeholder="Комментарий"
            name="comment"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.comment}
          />

            <button
              type="submit"
              className="form__comments-submit"
              onClick={() => {
                if (isValid && dirty) {
                  updateStage(2);
                  setIsFinallyStage(true);
                  handleSubmit();
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  })
                }
              }}
            >Оформить заказ
            </button>
          </div>


        </form>
      )

      }

    </Formik>

  )
}

export default CartForm
