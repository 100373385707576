import React, { useEffect, useRef, useState } from "react";
import { slidersApi } from "../api/api.js";

import Header from "../components/Header/Header";
import Promo from "../components/MainPage/Promo/Promo";
import Production from "../components/MainPage/Production/Production";
import Collection from "../components/MainPage/Collection/Collection";
import SliderAll from "../components/SliderAll/SliderAll";
import News from "../components/MainPage/News/News";
import SocialLinks from "../components/MainPage/SocialLinks/SocialLinks";
import CircleToTop from "../components/CircleToTop/CircleToTop";
import Footer from "../components/Footer/Footer";
import Preloader from "../components/Preloader/Preloader";
import { collectionApi, eventsApi } from "../api/api";
import { Helmet } from "react-helmet";

const MainPage = () => {
  const [dataProduction, setDataProduction] = useState([]);
  const [dataNewsProduct, setNewsProduct] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [news, setNews] = useState([]);
  const refVideo = useRef(null);

  const [categoriesCollection, setCategoriesCollection] = useState([]);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500)
  }, []);

  useEffect(() => {
    if (!isLoaded) {
      document.body.style.overflow = "hidden";
    }

    Promise.all([
      slidersApi.getProductionSlider().then((data) => setDataProduction(data)),
      slidersApi.getNewProducts().then((data) => setNewsProduct(data.items)),
      eventsApi
        .getSlider()
        .then((data) => setNews(data))
        .catch((e) => console.log(e)),
      collectionApi
        .getCollectionCategories()
        .then((data) => setCategoriesCollection(data)),
    ]).then(
      () => {
        setIsLoaded(true);
      },
      (reason) => {
        console.error(reason);
        setIsLoaded(false);
      },
    );
  }, []);

  useEffect(() => {
    if (isLoaded) {
      document.body.style.overflow = "";
    }
  }, [isLoaded]);

  return (
    <>
      <Helmet>
        <title>Evanty</title>
      </Helmet>

      <Preloader isLoaded={isLoaded} />

      <section className="promo">
        <video
          ref={refVideo}
          loop
          muted
          autoPlay
          playsInline
          className="promo__video"
          poster={require("../assets/img/main-video.png")}
          src={require("../assets/img/evantyVideo.webm")}
          preload="auto"
        ></video>
        <div className="container">
          <div className="header__wrapper">
            <Header color={"white"} />
          </div>
          <Promo />
        </div>
      </section>

      <section className="main">
        <div className="container">
          <Production dataProduction={dataProduction} />
        </div>

        <Collection collectionCategories={categoriesCollection} />

        <div className="container">
          <div className="new-product">
            <h2 className="new-product__title">Новинки</h2>

            <SliderAll
              dataNewsProduct={dataNewsProduct}
              isNavigationMobile={true}
            />
          </div>
        </div>

        <div className="container">
          <News data={news} />
        </div>

        <div className="container">
          <SocialLinks />
        </div>

        <CircleToTop />
      </section>

      <Footer />
    </>
  );
};

export default MainPage;
