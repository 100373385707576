import axios from "axios";
import { baseUrlReal } from "../constants.js";

const instanceReal = axios.create({
  baseURL: baseUrlReal,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export const slidersApi = {
  async getProductionSlider() {
    return (await instanceReal.get("/slider/products")).data;
  },
  async getCollectionSlider() {
    return (await instanceReal.get("/slider/collections")).data;
  },
  async getNewProducts() {
    return (await instanceReal.get("/slider/new")).data;
  },
};

export const productsApi = {
  async getAsideMenuItems() {
    return (await instanceReal.get("catalogs/categories")).data;
  },
  async getProductionItems(id) {
    return (await instanceReal.get(`/catalogs/${id}`)).data;
  },
  async getFuserId() {
    return (await instanceReal.get("/basket/getFuserId")).data;
  },
  async getDiscountMenu() {
    return (await instanceReal.get("discount/categories")).data;
  },
  async getDiscountItems(id) {
    return (await instanceReal.get(`/discount/${id}`)).data;
  },
  async postPhoneFooter(data) {
    return await axios({
      method: "post",
      url: `${baseUrlReal}/send/phone`,
      data,
    });
  },
};

export const detailCardApi = {
  async getDataDetailCard(id) {
    return (await instanceReal.get(`test/catalogs/item/${id}`)).data;
  },
  async postDetailOptions(data) {
    return (
      await axios({
        method: "post",
        url: `${baseUrlReal}/test/catalogs/properties`,
        data,
      })
    ).data;
  },
  // async postDetailOptions(data) {
  //   return (await instanceReal.get(`/price`, {
  //     params: {
  //       data
  //     }
  //   }))
  // },
  async getDataDiscountItem(id) {
    return (await instanceReal.get(`/discount/item/${id}`)).data;
  },
};

export const collectionApi = {
  async getCollectionCategories() {
    return (await instanceReal.get("/collections/categories")).data;
  },
  async getCollectionItems(id) {
    return (await instanceReal.get(`/collections/${id}`)).data;
  },
  async getCollectionDetailItem(id) {
    return (await instanceReal.get(`/collections/item/${id}`)).data;
  },
};

export const cartApi = {
  async postCartItems(data) {
    return await axios({
      method: "post",
      url: `${baseUrlReal}/order/add`,
      data,
    });
  },
  async getLocation() {
    return (await instanceReal.get("location")).data;
  },
  async getRecomendation() {
    return (await instanceReal.get("catalogs/recommends")).data;
  },
};

export const aboutFabricApi = {
  async getYears() {
    return (await instanceReal.get("/about/categories")).data;
  },
  async getYearContent(id) {
    return (await instanceReal.get(`/about/${id}`)).data;
  },
  async getInfo() {
    return (await instanceReal.get("/about")).data;
  },
};

export const vacancyApi = {
  async getVacancy() {
    return (await instanceReal.get("/jobs")).data;
  },
  postVacancy(data) {
    axios({
      method: "post",
      url: `${baseUrlReal}/jobs/response`,
      data,
    });
  },
};

export const dealersApi = {
  async getShopCity(search) {
    return (await instanceReal.get(`/dealer/city?search=${search}`)).data;
  },
  async getShopItems(id) {
    return (await instanceReal.get(`/dealer/${id}`)).data;
  },
};
export const howOrder = {
  async getOriginalShops() {
    return (await instanceReal.get("/dealer/main")).data;
  },
  async getDealerShops() {
    return (await instanceReal.get("/dealer/city")).data;
  },
  postHowOrder(data) {
    axios({
      headers: { "Content-Length": 5000 },
      method: "post",
      url: `${baseUrlReal}/question`,
      data,
    });
  },
};

export const eventsApi = {
  async getSlider(currentNewId = "") {
    return (
      await axios.get(
        `https://evanty.ru/api/news/slider?currentNewId=${currentNewId}`,
      )
    ).data;
  },
  async getDetailNews(id = "") {
    return (
        await axios.get(
            `https://evanty.ru/api/news/${id}`,
        )
    ).data;
  },
};

export const staticPageApi = {
  async getStaticPage() {
    return (await instanceReal.get("/static")).data;
  },

  async getStaticPageDetail(id) {
    return (await instanceReal.post(`/static/${id}`)).data;
  },

  async auth(id, data) {
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    return await instanceReal.post(`/static/${id}`, data, { headers: headers });
  },
};
