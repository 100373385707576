const ADD_TO_CART = "ADD_TO_CART";
const REMOVE_FROM_CART = "REMOVE_FROM_CART";
const SET_CART_DATA = "SET_CART_DATA";
const CHANGE_CUT_OPTIONS = "CHANGE_CUT_OPTIONS";
const CHANGE_CLOTH_COLOR = "CHANGE_CLOTH_COLOR";
const CHANGE_CATEGORIES_OPTIONS = "CHANGE_CATEGORIES_OPTIONS";
const CHANGE_COLOR_OPTIONS = "CHANGE_COLOR_OPTIONS";
const CHANGE_WOOD_COLOR_OPTIONS = "CHANGE_WOOD_COLOR_OPTIONS";
const CHANGE_SIZE_OPTIONS = "CHANGE_SIZE_OPTIONS";
const CHANGE_MECHANISM_OPTIONS = "CHANGE_MECHANISM_OPTIONS";
const RESET_STATE = "RESET_STATE";
const ADD_FORM_CART = "ADD_FORM_CART";
const CART_CHANGE_QUANTITY = "CART_CHANGE_QUANTITY";
const SET_DETAIL_CONTENT = "SET_DETAIL_CONTENT";
const SET_SELECTED_CLOTH = "SET_SELECTED_CLOTH";
const SET_SELECTED_WOOD = "SET_SELECTED_WOOD";
const SET_SELECTED_EQUIPMENT = "SET_SELECTED_EQUIPMENT";
const SET_SELECTED_CUT = "SET_SELECTED_CUT";
const SET_SELECTED_MECHANISM = "SET_SELECTED_MECHANISM";
const SET_COLLECTION_CARD_COORDINATES = "SET_COLLECTION_CARD_COORDINATES";
const SET_PRODUCTION_CARD_COORDINATES = "SET_PRODUCTION_CARD_COORDINATES";
const SET_DISCOUNT_CARD_COORDINATES = "SET_DISCOUNT_CARD_COORDINATES";

const UPDATE_PRICE = "UPDATE_PRICE";
const UPDATE_PRICE_CART = "UPDATE_PRICE_CART";
const UPDATE_TOTAL_PRICE = "UPDATE_TOTAL_PRICE";
const UPDATE_PROPERTIES = "UPDATE_PROPERTIES";

///card
const SELECT_COLOR = "SELECT_COLOR";
const SELECT_CUT_OPTIONS = "SELECT_CUT_OPTIONS";
const SELECT_WOOD = "SELECT_WOOD";
const SELECT_SIZE = "SELECT_SIZE";
const SELECT_MECHANISM = "SELECT_MECHANISM";

const SET_STATIC_PAGE_ID = "SET_STATIC_PAGE_ID";
const SET_IS_AUTH = "SET_IS_AUTH";
const SET_STATIC_PAGE = "SET_STATIC_PAGE";

export const initialState = {
  cartItems: [],
  totalPrice: 0,
  fUserId: 0,
  cartForm: {},
  dellItem: false,
  selectedContent: {},
  collectionCardCoordinates: 0,
  productionCardCoordinates: 0,
  staticPageId: null,
  isAuth: false,
  staticPage: {},
};

export function cartReducer(state, action) {
  switch (action.type) {
    case ADD_TO_CART:
      return {
        ...state,
        totalPrice: state.totalPrice + action.itemPrice,
        cartItems: [
          ...state.cartItems,
          { ...action.cartItem, itemId: Date.now() },
        ],
        fUserId: action.fUserId,
      };

    case REMOVE_FROM_CART:
      const quantity = action.quantity || 1;
      return {
        ...state,
        cartItems: state.cartItems.filter(
          (item) => item.itemId !== action.itemId
        ),
        totalPrice: state.totalPrice - action.itemPrice * quantity,
        dellItem: true,
      };

    case SET_CART_DATA:
      return {
        ...state,
        totalPrice: JSON.parse(localStorage.getItem("totalPrice")),
        cartItems: JSON.parse(localStorage.getItem("cartItems")),
      };

    case ADD_FORM_CART:
      return {
        ...state,
        cartForm: action.dataForm,
      };

    case CHANGE_CUT_OPTIONS:
      return {
        ...state,
        cartItems: [
          ...state.cartItems.map((el) => {
            if (el.itemId === action.itemId) {
              return {
                ...el,
                properties: {
                  ...el.properties,
                  type: {
                    ...el.properties.type,
                    items: [
                      ...el.properties.type.items.map((type) => {
                        if (type.id === action.optionId) {
                          return {
                            ...type,
                            selected: true,
                          };
                        } else {
                          return {
                            ...type,
                            selected: false,
                          };
                        }
                      }),
                    ],
                  },
                },
              };
            } else {
              return el;
            }
          }),
        ],
      };

    case CHANGE_CLOTH_COLOR:
      return {
        ...state,
        cartItems: [
          ...state.cartItems.map((el) => {
            if (el.itemId === action.itemId) {
              return {
                ...el,
                properties: {
                  ...el.properties,
                  clothColor: {
                    ...el.properties.clothColor,
                    items: [
                      ...el.properties.clothColor.items.map((cloth) => {
                        if (cloth.id === action.clothId) {
                          return {
                            ...cloth,
                            selected: true,
                          };
                        } else {
                          return {
                            ...cloth,
                            selected: false,
                          };
                        }
                      }),
                    ],
                  },
                },
              };
            } else {
              return el;
            }
          }),
        ],
      };

    case CHANGE_CATEGORIES_OPTIONS:
      return {
        ...state,
        cartItems: [
          ...state.cartItems.map((el) => {
            if (el.itemId === action.itemId) {
              return {
                ...el,
                clothes: [
                  ...el.clothes.map((cloth) => {
                    if (cloth.id === action.clothId) {
                      return {
                        ...cloth,
                        categories: [
                          ...cloth.categories.map((category) => {
                            if (category.id === action.categoriesId) {
                              return {
                                ...category,
                                selected: true,
                              };
                            } else {
                              return {
                                ...category,
                                selected: false,
                              };
                            }
                          }),
                        ],
                      };
                    } else {
                      return cloth;
                    }
                  }),
                ],
              };
            } else {
              return el;
            }
          }),
        ],
      };

    case CHANGE_COLOR_OPTIONS:
      return {
        ...state,
        cartItems: [
          ...state.cartItems.map((el) => {
            if (el.itemId === action.itemId) {
              return {
                ...el,
                clothes: [
                  ...el.clothes.map((cloth) => {
                    if (cloth.id === action.clothId) {
                      return {
                        ...cloth,
                        categories: [
                          ...cloth.categories.map((category) => {
                            if (category.id === action.categoriesId) {
                              return {
                                ...category,
                                colorClothes: [
                                  ...category.colorClothes.map((color) => {
                                    if (color.id === action.colorId) {
                                      return {
                                        ...color,
                                        selected: true,
                                      };
                                    } else {
                                      return {
                                        ...color,
                                        selected: false,
                                      };
                                    }
                                  }),
                                ],
                              };
                            } else {
                              return {
                                ...category,
                              };
                            }
                          }),
                        ],
                      };
                    } else {
                      return cloth;
                    }
                  }),
                ],
              };
            } else {
              return el;
            }
          }),
        ],
      };

    case CHANGE_WOOD_COLOR_OPTIONS:
      return {
        ...state,
        cartItems: [
          ...state.cartItems.map((el) => {
            if (el.itemId === action.itemId) {
              return {
                ...el,
                properties: {
                  ...el.properties,
                  woodColor: {
                    ...el.properties.woodColor,
                    items: [
                      ...el.properties.woodColor.items.map((wood) => {
                        if (wood.id === action.woodId) {
                          return {
                            ...wood,
                            selected: true,
                          };
                        } else {
                          return {
                            ...wood,
                            selected: false,
                          };
                        }
                      }),
                    ],
                  },
                },
              };
            } else {
              return el;
            }
          }),
        ],
      };

    case CHANGE_SIZE_OPTIONS:
      return {
        ...state,
        cartItems: [
          ...state.cartItems.map((el) => {
            if (el.itemId === action.itemId) {
              return {
                ...el,
                properties: {
                  ...el.properties,
                  equipment: {
                    ...el.properties.equipment,
                    items: [
                      ...el.properties.equipment.items.map((size) => {
                        if (size.id === action.sizeId) {
                          return {
                            ...size,
                            selected: true,
                          };
                        } else {
                          return {
                            ...size,
                            selected: false,
                          };
                        }
                      }),
                    ],
                  },
                },
              };
            } else {
              return el;
            }
          }),
        ],
      };

    case CHANGE_MECHANISM_OPTIONS:
      return {
        ...state,
        cartItems: [
          ...state.cartItems.map((el) => {
            if (el.itemId === action.itemId) {
              return {
                ...el,
                properties: {
                  ...el.properties,
                  mechanism: {
                    ...el.properties.mechanism,
                    items: [
                      ...el.properties.mechanism.items.map((item) => {
                        if (item.id === action.mechanismId) {
                          return {
                            ...item,
                            selected: true,
                          };
                        } else {
                          return {
                            ...item,
                            selected: false,
                          };
                        }
                      }),
                    ],
                  },
                },
              };
            } else {
              return el;
            }
          }),
        ],
      };

    case SELECT_COLOR:
      return {
        ...state,
        cartItems: [
          ...state.cartItems.map((el) => {
            if (el.itemId === action.itemId) {
              return {
                ...el,
                properties: {
                  ...el.properties,
                  clothColor: {
                    ...el.properties.clothColor,
                    value: action.value,
                  },
                },
              };
            } else {
              return el;
            }
          }),
        ],
      };

    case SELECT_MECHANISM:
      return {
        ...state,
        cartItems: [
          ...state.cartItems.map((el) => {
            if (el.itemId === action.itemId) {
              return {
                ...el,
                properties: {
                  ...el.properties,
                  mechanism: {
                    ...el.properties.mechanism,
                    value: action.value,
                  },
                },
              };
            } else {
              return el;
            }
          }),
        ],
      };

    case SELECT_SIZE:
      return {
        ...state,
        cartItems: [
          ...state.cartItems.map((el) => {
            if (el.itemId === action.itemId) {
              return {
                ...el,
                properties: {
                  ...el.properties,
                  equipment: {
                    ...el.properties.equipment,
                    value: action.value,
                  },
                },
              };
            } else {
              return el;
            }
          }),
        ],
      };

    case SELECT_WOOD:
      return {
        ...state,
        cartItems: [
          ...state.cartItems.map((el) => {
            if (el.itemId === action.itemId) {
              return {
                ...el,
                properties: {
                  ...el.properties,
                  woodColor: {
                    ...el.properties.woodColor,
                    value: action.value,
                  },
                },
              };
            } else {
              return el;
            }
          }),
        ],
      };

    case SELECT_CUT_OPTIONS:
      return {
        ...state,
        cartItems: [
          ...state.cartItems.map((el) => {
            if (el.itemId === action.itemId) {
              return {
                ...el,
                properties: {
                  ...el.properties,
                  type: {
                    ...el.properties.type,
                    value: action.value,
                  },
                },
              };
            } else {
              return el;
            }
          }),
        ],
      };

    case SET_SELECTED_MECHANISM:
      return {
        ...state,
        cartItems: [
          ...state.cartItems.map((el) => {
            if (el.itemId === action.itemId) {
              return {
                ...el,
                properties: {
                  ...el.properties,
                  mechanism: {
                    ...el.properties.mechanism,
                    value: action.value,
                  },
                },
              };
            } else {
              return el;
            }
          }),
        ],
      };

    case RESET_STATE:
      return {
        ...state,
        cartItems: [],
        totalPrice: 0,
        cartForm: {},
      };

    case CART_CHANGE_QUANTITY:
      return {
        ...state,
        cartItems: state.cartItems.map((el) => {
          if (el.itemId === action.id) {
            return {
              ...el,
              quantity: action.quantity,
              totalPrice: action.quantity * action.price,
            };
          } else {
            return el;
          }
        }),
      };

    case SET_DETAIL_CONTENT:
      return {
        ...state,
        selectedContent: {
          ...action.data,
          properties: {
            ...action.data?.properties,
            clothColor: {
              ...action.data.properties?.clothColor,
              value: action.data.properties?.clothColor?.items
                .map((el) => (el.selected ? el.value : null))
                .filter((el) => !!el)[0],
            },
            woodColor: {
              ...action.data.properties?.woodColor,
              value: action.data.properties?.woodColor?.items
                .map((el) => (el.selected ? el.value : null))
                .filter((el) => !!el)[0],
            },
            mechanism: {
              ...action.data.properties?.mechanism,
              value: action.data.properties?.mechanism?.items
                .map((el) => (el.selected ? el.value : null))
                .filter((el) => !!el)[0],
            },
            equipment: {
              ...action.data.properties?.equipment,
              value: action.data.properties?.equipment?.items
                .map((el) => (el.selected ? el.value : null))
                .filter((el) => !!el)[0],
            },
          },
        },
      };

    case SET_SELECTED_CLOTH:
      return {
        ...state,
        selectedContent: {
          ...state.selectedContent,
          properties: {
            ...state.selectedContent.properties,
            clothColor: {
              ...state.selectedContent.properties.clothColor,
              items: state.selectedContent.properties.clothColor.items.map(
                (el) =>
                  el.id === action.id
                    ? {
                        ...el,
                        selected: true,
                      }
                    : { ...el, selected: false }
              ),
              value: action.title,
            },
          },
        },
      };

    // case UPDATE_PRICE_CLOTH:
    //   return {
    //     ...state,
    //     selectedContent: {
    //       ...state.selectedContent,
    //       totalPrice: state.selectedContent.properties.clothColor.items.map(el => {
    //         if (el.selected) {
    //           return el.price;
    //         }
    //       }).filter(el => !!el).reduce((acc, cur) => acc + cur, state.selectedContent.price),
    //       // price: state.selectedContent.price + state.selectedContent.addPrice,
    //     }
    //   }

    case SET_SELECTED_WOOD:
      return {
        ...state,
        selectedContent: {
          ...state.selectedContent,
          properties: {
            ...state.selectedContent.properties,
            woodColor: {
              ...state.selectedContent.properties.woodColor,
              items: state.selectedContent.properties.woodColor.items.map(
                (el) =>
                  el.id === action.id
                    ? {
                        ...el,
                        selected: true,
                      }
                    : { ...el, selected: false }
              ),
              value: action.title,
            },
          },
        },
      };

    case SET_SELECTED_EQUIPMENT:
      return {
        ...state,
        selectedContent: {
          ...state.selectedContent,
          properties: {
            ...state.selectedContent.properties,
            equipment: {
              ...state.selectedContent.properties.equipment,
              items: state.selectedContent.properties.equipment.items.map(
                (el) =>
                  el.id === action.id
                    ? {
                        ...el,
                        selected: true,
                      }
                    : { ...el, selected: false }
              ),
              value: action.title,
            },
          },
        },
      };

    case SET_SELECTED_CUT:
      return {
        ...state,
        selectedContent: {
          ...state.selectedContent,
          properties: {
            ...state.selectedContent.properties,
            type: {
              ...state.selectedContent.properties.type,
              items: state.selectedContent.properties.type.items.map((el) =>
                el.id === action.id
                  ? {
                      ...el,
                      selected: true,
                    }
                  : { ...el, selected: false }
              ),
              value: action.title,
            },
          },
        },
      };

    case UPDATE_PRICE: {
      return {
        ...state,
        selectedContent: {
          ...state.selectedContent,
          price: action.price,
        },
      };
    }

    case UPDATE_PRICE_CART: {
      return {
        ...state,
        cartItems: state.cartItems.map((el) => {
          if (el.parentId === action.id) {
            return {
              ...el,
              price: action.price,
              totalPrice: action.quantity * action.price,
            };
          } else {
            return el;
          }
        }),
      };
    }

    case UPDATE_TOTAL_PRICE: {
      return {
        ...state,
        totalPrice: state.cartItems
          .map((el) => (el.totalPrice ? el.totalPrice : null))
          .reduce((acc, cur) => acc + cur, 0),
      };
    }

    case UPDATE_PROPERTIES:
      return {
        ...state,
        selectedContent: {
          ...state.selectedContent,
          properties: {
            ...state.selectedContent.properties,
            ...action.properties.properties,
          },
        },
      };

    case SET_COLLECTION_CARD_COORDINATES:
      return {
        ...state,
        collectionCardСoordinates: action.collectionCardСoordinates,
      };

    case SET_PRODUCTION_CARD_COORDINATES:
      return {
        ...state,
        productionCardСoordinates: action.productionCardСoordinates,
      };

    case SET_DISCOUNT_CARD_COORDINATES:
      return {
        ...state,
        discountCardCoordinates: action.payload,
      };

    case SET_STATIC_PAGE_ID:
      return {
        ...state,
        staticPageId: action.staticPageId,
      };

    case SET_IS_AUTH:
      return {
        ...state,
        isAuth: action.isAuth,
      };

    case SET_STATIC_PAGE:
      return {
        ...state,
        staticPage: action.staticPage,
      };

    default:
      return state;
  }
}

export const addToCart = (cartItem, itemPrice, fUserId) => ({
  type: ADD_TO_CART,
  cartItem,
  itemPrice,
  fUserId,
});
export const addFormCart = (dataForm) => ({ type: ADD_FORM_CART, dataForm });
export const setDetailContent = (data) => ({ type: SET_DETAIL_CONTENT, data });
export const removeFromCart = (itemId, itemPrice, quantity) => ({
  type: REMOVE_FROM_CART,
  itemId,
  itemPrice,
  quantity,
});
export const changeCutOptions = (itemId, optionId) => ({
  type: CHANGE_CUT_OPTIONS,
  itemId,
  optionId,
});
export const changeClothColor = (itemId, clothId) => ({
  type: CHANGE_CLOTH_COLOR,
  itemId,
  clothId,
});
export const changeColorWoodOptions = (itemId, woodId) => ({
  type: CHANGE_WOOD_COLOR_OPTIONS,
  itemId,
  woodId,
});
export const changeSizeOptions = (itemId, sizeId) => ({
  type: CHANGE_SIZE_OPTIONS,
  itemId,
  sizeId,
});
export const changeMechanismOptions = (itemId, mechanismId) => ({
  type: CHANGE_MECHANISM_OPTIONS,
  itemId,
  mechanismId,
});
export const setCartData = () => ({ type: SET_CART_DATA });
export const resetState = () => ({ type: RESET_STATE });
export const setQuantity = (id, quantity, price) => ({
  type: CART_CHANGE_QUANTITY,
  id,
  quantity,
  price,
});

export const setSelectedWood = (id, title) => ({
  type: SET_SELECTED_WOOD,
  id,
  title,
});
export const setSelectedColor = (id, title) => ({
  type: SET_SELECTED_CLOTH,
  id,
  title,
});
export const setSelectedEquipment = (id, title) => ({
  type: SET_SELECTED_EQUIPMENT,
  id,
  title,
});
export const setSelectedCut = (id, title) => ({
  type: SET_SELECTED_CUT,
  id,
  title,
});
export const setSelectedMechanism = (id, title) => ({
  type: SET_SELECTED_MECHANISM,
  id,
  title,
});

export const setCollectionCardСoordinates = (collectionCardСoordinates) => ({
  type: SET_COLLECTION_CARD_COORDINATES,
  collectionCardСoordinates,
});

export const setProductionCardСoordinates = (productionCardСoordinates) => ({
  type: SET_PRODUCTION_CARD_COORDINATES,
  productionCardСoordinates,
});

export const setDiscountCardCoordinates = (payload) => ({
  type: SET_DISCOUNT_CARD_COORDINATES,
  payload,
});

export const updatePrice = (price) => ({ type: UPDATE_PRICE, price });
export const updatePriceCart = (id, price, quantity) => ({
  type: UPDATE_PRICE_CART,
  id,
  price,
  quantity,
});
export const updateTotalPrice = () => ({ type: UPDATE_TOTAL_PRICE });
export const updateProperties = (properties) => ({
  type: UPDATE_PROPERTIES,
  properties,
});

export const selectClothColor = (itemId, value) => ({
  type: SELECT_COLOR,
  itemId,
  value,
});
export const selectMechanism = (itemId, value) => ({
  type: SELECT_MECHANISM,
  itemId,
  value,
});
export const selectSize = (itemId, value) => ({
  type: SELECT_SIZE,
  itemId,
  value,
});
export const selectWoodColor = (itemId, value) => ({
  type: SELECT_WOOD,
  itemId,
  value,
});
export const selectType = (itemId, value) => ({
  type: SELECT_CUT_OPTIONS,
  itemId,
  value,
});

export const setStaticPageId = (staticPageId) => ({
  type: SET_STATIC_PAGE_ID,
  staticPageId,
});
export const setIsAuth = (isAuth) => ({
  type: SET_IS_AUTH,
  isAuth,
});
export const setStaticPage = (staticPage) => ({
  type: SET_STATIC_PAGE,
  staticPage,
});
