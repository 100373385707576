import React, {useContext, useEffect, useRef} from 'react'
import {Swiper, SwiperSlide} from 'swiper/react';

import './CartHeader.scss';
import 'swiper/swiper.scss';
import {CartContext} from "../../../context";
import {numberWithSpaces} from "../../../Functions";
import {useWidthContext} from "../../../widthContext";

const CartHeader = ({stage, updateStage}) => {
  const [state] = useContext(CartContext);
  const refSlider = useRef(null);
  const {isMobile} = useWidthContext();


  useEffect(() => {
    if (refSlider.current !== null) {
      refSlider.current.swiper.slideTo(stage)
    }

  }, [stage])

  return (
    <div className="header-cart">
      {
        stage === 0 ? (
          <div className="header-cart__text">
            <h5 className="header-cart__title">{
              state.cartItems.map((el, index) => {
                return (<span key={`${el.id}_${index}_${el.name}`}>{el.title}</span>);
              })
            }</h5>
            <div className="header-cart__price">от {numberWithSpaces(state.totalPrice)} &#8381;</div>
          </div>
        ) : ''
      }
      {
        isMobile ? (
          <>
            <Swiper
              ref={refSlider}
              className='header-cart__way'
              spaceBetween={0}
              simulateTouch={false}
              allowTouchMove={false}
              slidesPerView={'auto'}
              centeredSlidesBounds={true}
            >{/*??????*/}
              <SwiperSlide className='header-cart__way-title active'>
                <span>Заказ</span>
              </SwiperSlide>

              <SwiperSlide
                style={{width: 250}}
                className={`header-cart__way-title ${stage >= 1 ? 'active' : ''}`}
              >
                <span>Оформление</span>
              </SwiperSlide>

              <SwiperSlide
                style={{width: 200}}
                className={`header-cart__way-title ${stage === 2 ? 'active' : ''}`}
              >
                <span>Готово</span>
              </SwiperSlide>
            </Swiper>
            {
              stage < 1 ? (
                <span className="header-cart__desc">Проверьте все детали перед заказом</span>
              ) : ''
            }
          </>
        ) : (
          <>
            <div className="header-cart__way">
              <h4
                className="header-cart__way-title active"
              ><span>Заказ</span></h4>
              <h4
                className={`header-cart__way-title ${stage >= 1 ? 'active' : ''}`}
              ><span>Оформление</span></h4>
              <h4
                className={`header-cart__way-title ${stage === 2 ? 'active' : ''}`}
              ><span>Готово</span></h4>
            </div>

            {
              stage < 2 ? (
                <span className="header-cart__desc">Проверьте все детали перед заказом</span>
              ) : ''
            }
          </>
        )
      }
    </div>
  )
}

export default CartHeader
