import React from 'react'
import {Swiper, SwiperSlide} from 'swiper/react';
import SwiperCore, {Navigation} from 'swiper';
import {initNav} from './../../../constants';

import 'swiper/components/navigation/navigation.scss';
import 'swiper/swiper.scss';
import './OriginalShop.scss';

const OriginalShop = ({dataOriginalShop}) => {

  SwiperCore.use([Navigation]);

  return (
    <>
      {
        dataOriginalShop.length > 0 && (
          <Swiper
            className='original-shop__slider'
            spaceBetween={20}
            slidesPerView={2}
            onInit={(swiper) => dataOriginalShop.length !== 0 ? swiper.update() : ''}
            navigation={true}
            onSwiper={(swiper) => dataOriginalShop.length > 2 ? initNav(swiper) : ""}
          >
            {
              dataOriginalShop.map(el => (
                <SwiperSlide
                  key={el._id}
                  className='original-shop__item'
                >
                  <h4 className="original-shop__item-name">{el.city}</h4>
                  <div className="original-shop__item-wrapper">
                    <img src={el.image} alt="" className="original-shop__item-img"/>
                    <div className="original-shop__item-text">
                      <h6 className="original-shop__item-shop">{el.title}</h6>
                      <div className="original-shop__item-address" dangerouslySetInnerHTML={{__html: el.text}}/>
                    </div>
                  </div>
                </SwiperSlide>
              ))
            }
          </Swiper>
        )
      }

    </>
  )
}

export default OriginalShop
