import React, { useEffect } from "react";

import "./ModalVacancy.scss";
import { Field, Formik } from "formik";
import { validateForm } from "../../Functions";
import ModalSuccess from "./ModalSuccess";
import { vacancyApi } from "../../api/api";

const ModalVacancy = ({
  isOpenModal,
  setIsOpenModal,
  modalEl,
  vacancyId,
  isSuccessModal,
  setIsSuccessModal,
}) => {
  // const [animationModalClass, closeModal] = useFadeInModalAnimation(isOpenModal, setIsOpenModal);

  useEffect(() => {
    if (isOpenModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [isOpenModal]);

  return (
    <>
      <div
        className={`overlay ${isOpenModal ? "active" : ""}`}
        onClick={() => setIsOpenModal(false)}
      />
      <ModalSuccess
        isSuccessModal={isSuccessModal}
        setIsSuccessModal={setIsSuccessModal}
      />
      <div
        className={`vacancy-modal ${isOpenModal ? "fadeIn" : "fadeOut"}`}
        ref={modalEl}
      >
        <button
          type="button"
          className="vacancy-modal__close"
          onClick={() => {
            setIsOpenModal(false);
          }}
        />
        <h3 className="vacancy-modal__title">
          Введите свои данные, чтобы откликнуться
        </h3>

        <Formik
          initialValues={{
            name: "",
            phone: "",
            email: "",
            politic: false,
            comment: "",
          }}
          validate={(values) => validateForm(values)}
          onSubmit={(values, { setSubmitting }) => {
            vacancyApi.postVacancy(
              JSON.stringify({ ...values, _id: vacancyId }, null, 2)
            );
            setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
            isSubmitting,
            isValid,
            dirty,
            /* and other goodies */
          }) => (
            <form className="vacancy-modal__form" onSubmit={handleSubmit}>
              <div className="vacancy-modal__items">
                <div className="vacancy-modal__item">
                  <input
                    type="text"
                    className={`vacancy-modal__input ${errors.name}`}
                    name="name"
                    id="data-name"
                    placeholder="Имя"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                  />
                  <label htmlFor="data-name" className="vacancy-modal__label">
                    Ваше имя
                  </label>
                </div>

                <div className="vacancy-modal__item">
                  <input
                    type="text"
                    name="phone"
                    className={`vacancy-modal__input ${errors.phone}`}
                    id="data-phone"
                    placeholder="Телефон"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.phone}
                  />
                  <label htmlFor="data-phone" className="vacancy-modal__label">
                    +7 (900) 000 00-00
                  </label>
                </div>

                <div className="vacancy-modal__item">
                  <input
                    type="text"
                    className={`vacancy-modal__input ${errors.email}`}
                    id="data-email"
                    name="email"
                    placeholder="Почта для связи с вами"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                  />
                  <label htmlFor="data-email" className="vacancy-modal__label">
                    E-mail
                  </label>
                </div>
                <div className="vacancy-modal__politic">
                  <Field
                    type="checkbox"
                    name="politic"
                    className="vacancy-modal__check"
                    id="data-politic"
                  />
                  <label
                    htmlFor="data-politic"
                    className="vacancy-modal__check-title"
                  >
                    <a
                      href="https://evanty.ru/upload/%D0%9F%D0%BE%D0%BB%D0%B8%D1%82%D0%B8%D0%BA%D0%B0_%D0%BE%D0%BF%D0%B5%D1%80%D0%B0%D1%82%D0%BE%D1%80%D0%B0_%D0%B2_%D0%BE%D1%82%D0%BD%D0%BE%D1%88%D0%B5%D0%BD%D0%B8%D0%B8_%D0%BE%D0%B1%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D0%BA%D0%B8_%D0%BF%D0%B5%D1%80%D1%81%D0%B4%D0%B0%D0%BD%D0%BD%D1%8B%D1%85.pdf"
                      download
                    >
                      Даю согласие на обработку моих персональных данных в
                      соответствии с 152-ФЗ
                    </a>
                  </label>
                </div>
              </div>
              <textarea
                cols="30"
                rows="10"
                className="vacancy-modal__comments"
                placeholder="Комментарий"
                name="comment"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.comment}
              />

              <button
                type="submit"
                className="vacancy-modal__submit"
                onClick={() => {
                  if (isValid && dirty) {
                    // handleSubmit();
                    setTimeout(() => handleReset());
                    setIsOpenModal(false);
                    setIsSuccessModal(true);
                  }
                }}
              >
                Откликнуться
              </button>
            </form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default ModalVacancy;
