import React, {useEffect, useState} from 'react'
import {howOrder} from './../api/api';

import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import OriginalShop from './../components/HowOrder/OriginalShop/OriginalShop';
import DealersShop from './../components/HowOrder/DealersShop/DealersShop';
import FeedbackForm from './../components/HowOrder/FeedbackForm/FeedbackForm';
import Shops from './../components/Shops/Shops';

import '../components/HowOrder/HowOrder.scss';
import {Link} from 'react-router-dom';
import Preloader from "../components/Preloader/Preloader";
import Helmet from "react-helmet";
import {useWidthContext} from "../widthContext";

const HowOrder = () => {
  const {isMobile} = useWidthContext();
  const [dataOriginalShop, setOriginalShop] = useState([]);
  const [dataDealersShop, setDealersShop] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    if (!isLoaded) {
      document.body.style.overflow = 'hidden';
    }

    Promise.all([
      howOrder.getOriginalShops()
        .then(data => setOriginalShop(data)),
      howOrder.getDealerShops()
        .then(data => setDealersShop(data[1].shops)),
    ])
      .then(() => setIsLoaded(true))

  }, [])

  useEffect(() => {
    if (isLoaded) {
      document.body.style.overflow = '';
    }
  }, [isLoaded]);

  return (
    <>
      <Preloader isLoaded={isLoaded}/>

      <Helmet>
        <title>Как заказать?</title>
        <meta name="description" content="Nested component"/>
      </Helmet>

      <div className='how-order'>
        <div className="container">
          <Header color={'black'}/>

          <h1 className="how-order__title">Как заказать?</h1>
          <div className="how-order__desc">Покупайте мебель Evanty в фирменных салонах фабрики или у официальных представителей в вашем регионе.</div>

          <div className="how-order__wrapper">

            {
              isMobile ? (
                <Shops/>
              ) : (
                <>
                  <div className='original-shop'>
                    <h2 className="original-shop__title">Фирменные салоны фабрики</h2>
                    <OriginalShop dataOriginalShop={dataOriginalShop}/>
                  </div>

                  {/*<DealersShop dataDealersShop={dataDealersShop}/>*/}
                </>
              )
            }


          </div>


          <div className="how-order__online">
            <div className="how-order__online-wrapper">
              <h2 className="how-order__online-title">Оформление заказа онлайн</h2>
              <p className="how-order__online-desc">Ознакомьтесь с нашими товарами на странице каталога и выберите подходящую вам мебель. Наполните корзину и оформите предварительный заказ. Наш менеджер свяжется с вами для уточнения деталей вашего заказ</p>
              {
                isMobile ? ('') : (
                  <Link className="how-order__online-link" to="/production">в каталог</Link>
                )
              }
            </div>
            <img src={require('./../assets/img/howorder.png')} alt="" className="how-order__online-img"/>
            {
              isMobile ? (
                <Link className="how-order__online-link" to="/production">в каталог</Link>
              ) : ('')
            }
          </div>

          {/*<div className="how-order__pay">*/}
          {/*  <h2 className="how-order__pay-title">Способы оплаты</h2>*/}
          {/*  <div className="how-order__pay-list">*/}
          {/*    <div className="how-order__pay-item">*/}
          {/*      <div className="how-order__pay-type">Для физических лиц</div>*/}
          {/*      <div className="how-order__pay-desc">Оплата наличными, банковской картой в наших салонах или курьеру при*/}
          {/*        доставке*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*    <div className="how-order__pay-item">*/}
          {/*      <div className="how-order__pay-type">Для юридических лиц</div>*/}
          {/*      <div className="how-order__pay-desc">Безналичный перевод на основании счета.</div>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}

          <div className="how-order__feedback">
            <div className="how-order__feedback-wrapper">
              <h1 className="how-order__feedback-title">Остались вопросы?</h1>
              <div className="how-order__feedback-desc">Оформить предварительный заказ, получить консультацию о продукции, а также узнать контакты представителя в вашем городе, вы можете через сайт. </div>
            </div>

            <FeedbackForm/>
          </div>


        </div>


        <Footer/>
      </div>
    </>
  )
}

export default HowOrder
