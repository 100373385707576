import React from 'react';

import './ModalSuccess.scss';
import {useFadeInModalAnimation} from "../../Functions";

const ModalSuccess = ({isSuccessModal, setIsSuccessModal}) => {
  const [animationModalClass, closeModal] = useFadeInModalAnimation(isSuccessModal, setIsSuccessModal, true);




  return (
    <>
      <div
        className={`overlay ${isSuccessModal ? 'active' : ''}`}
        onClick={() => closeModal()}
      />
    <div
      className={`success-modal ${animationModalClass}`}
    >
      <svg className="success-modal__img" width="186" height="186" viewBox="0 0 186 186" fill="none"
           xmlns="http://www.w3.org/2000/svg">
        <path
          d="M93 0C41.6378 0 0 41.6378 0 93C0 144.362 41.6378 186 93 186C144.362 186 186 144.362 186 93C186 41.6378 144.362 0 93 0ZM93 180.188C44.8492 180.188 5.8125 141.151 5.8125 93.0029C5.8125 44.8492 44.8492 5.8125 93 5.8125C141.151 5.8125 180.188 44.8492 180.188 93.0029C180.188 141.151 141.151 180.188 93 180.188Z"
          fill="#383C42"/>
        <path
          d="M131.337 70.3953L127.227 66.2859L81.3753 112.141L58.7704 89.5359L54.661 93.6482L80.7301 119.711L81.3753 119.072L82.0204 119.711L131.337 70.3953Z"
          fill="#383C42"/>
      </svg>
      <h3 className="success-modal__title">Ваша заявка успешно отправлена</h3>
      <div className="success-modal__subtitle">Наш специалист перезвонит вам</div>
      <button
        className="success-modal__close"
        onClick={() => {
          closeModal();
        }}
      />
    </div>
      </>
  );
};

export default ModalSuccess;