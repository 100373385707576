import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import { isMobile, initNav } from "../../constants";
import "./SliderAll.scss";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import { Link } from "react-router-dom";

const SliderAll = ({
  dataNewsProduct,
  isNavigationMobile,
  isDetail,
  setIsLoaded,
  isSubtitle,
}) => {
  if (isMobile) {
    SwiperCore.use([Navigation]);
  }


  return (
    <>
      {dataNewsProduct.length > 0 && (
        <Swiper
          className="new-product__slider"
          spaceBetween={isMobile ? 10 : 20}
          // width={isMobile ? 330 : 385}
          onInit={(swiper) =>
            dataNewsProduct.length !== 0 ? swiper.update() : ""
          }
          navigation={!!(isMobile && isNavigationMobile)}
          onSwiper={(swiper) =>
            isMobile && isNavigationMobile ? initNav(swiper) : ""
          }
          onTouchMove={(swiper) => (swiper.el.style.transform = "scale(0.93)")}
          onTouchEnd={(swiper) => (swiper.el.style.transform = "scale(1)")}
          // onSlideChange={lastSlideFix}
          slidesPerView={"auto"}
        >
          {isDetail
            ? dataNewsProduct.map((el, index) => (
                <SwiperSlide
                  key={`${el._id}__${index}`}
                  style={{ width: "auto", marginRight: isMobile ? 10 : 20 }}
                >
                  <Link
                    to={`/detailCard/${el._id}/${!!el._code ? el._code : el.title}`}
                    onClick={() => setIsLoaded(false)}
                    className="new-product__item"
                  >
                    <div className="new-product__item--wrap-img">
                      <img
                        src={el.image}
                        alt=""
                        className="new-product__item-img"
                      />
                      <span className="new-product__item-link">Подробнее</span>
                    </div>
                    {isSubtitle && (
                      <h5 className="new-product__item-subtitle">
                        {el.sectionName}
                      </h5>
                    )}
                    <h5 className="new-product__item-title">{el.title}</h5>
                  </Link>
                </SwiperSlide>
              ))
            : dataNewsProduct.map((el, index) => (
                <SwiperSlide
                  key={`${el._id}__${index}`}
                  style={{ width: "auto", marginRight: isMobile ? 10 : 20 }}
                >
                  <Link
                    to={`/detailCard/${el._id}/${!!el._code ? el._code : el.title}`}
                    className="new-product__item"
                  >
                    <img
                      src={el.image}
                      alt=""
                      className="new-product__item-img"
                    />
                    <span className="new-product__item-link">Подробнее</span>
                    <h5 className="new-product__item-title">{el.title}</h5>
                  </Link>
                </SwiperSlide>
              ))}
        </Swiper>
      )}
    </>
  );
};

export default SliderAll;
