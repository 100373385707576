import React, { useContext } from "react";
import "./DetailItems.scss";
import { Link } from "react-router-dom";
import { CartContext } from "../../context";
import { setCollectionCardСoordinates } from "../../reducers/cartReducer";

const DetailItems = ({ dataDetailItems }) => {
  const [state, dispatch] = useContext(CartContext);

  return (
    <div className="collection-group__list-wrapper">
      {!!dataDetailItems.items &&
        dataDetailItems.items.map((el) => (
          <Link
            to={`/detailCard/${el._id}/${el._code ? el._code : el.title}`}
            key={el._id}
            id={`product-${el._id}`}
            className="collection-group__item"
            onClick={() => {
              dispatch(setCollectionCardСoordinates(window.pageYOffset));
            }}
          >
            <img src={el.image} alt="" className="collection-group__item-img" />
            <div className="products-model__item-subtitle">{el.section}</div>
            <div className="collection-group__item-name">{el.title}</div>
          </Link>
        ))}
    </div>
  );
};

export default DetailItems;
