import React, { useEffect, useState } from "react";
import { dealersApi } from "../../api/api";
import Preloader from "../Preloader/Preloader";
import { YMaps, Map, Placemark } from "@pbe/react-yandex-maps";
import { useWidthContext } from "../../widthContext";

const defaultState = { center: [55.75, 37.57], zoom: 6 };
const defaultDesc =
  "Мебель Эванти представлена во многих городах России. Посмотреть и заказать изделия можно в салонах наших партнёров.";

const Shops = () => {
  const [dataShopItem, setDataShopItem] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [currentCityId, setCurrentCityId] = useState(null);
  const [currentCoordinate, setCurrentCoordinate] = useState(null);
  const [marks, setMarks] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [isFirst, setIsFirst] = useState(true);
  const { isMobile } = useWidthContext();


  useEffect(() => {
    dealersApi.getShopCity("").then((data) => {
      setIsLoaded(true);
      setIsFirst(false);
      setDataShopItem(data);
      setSearchData(data);
      const markArr = data?.reduce(
        (acc, cur) => [
          ...acc,
          ...cur.shops.reduce((total, shop) => [...total, ...shop.items], []),
        ],
        [],
      );
      setMarks(markArr);
    });
  }, []);

  useEffect(() => {
    if (!isFirst) {
      dealersApi.getShopCity(searchValue).then((data) => {
        setSearchData(data);
      });
    }
  }, [searchValue]);

  const handleChooseCity = (data) => {
    setCurrentCityId(data._id);
    setCurrentCoordinate({
      x: data?.items[0]?.xcoordinate,
      y: data?.items[0]?.ycoordinate,
    });
  };

  const handleChooseShop = (x, y) => {
    setCurrentCoordinate({
      x,
      y,
    });
  };

  return (
    <>
      <Preloader isLoaded={isLoaded} />

      <div className="dealers__wrapper">
        <div className="dealers__wrap">
          <div className="dealers__pos">
            <YMaps>
              <Map
                width={isMobile ? "100vw" : "60vw"}
                height={isMobile ? "80vh" : "100vh"}
                state={
                  currentCoordinate
                    ? {
                        center: [
                          Number(currentCoordinate?.x ?? 55.75),
                          Number(currentCoordinate?.y ?? 37.57),
                        ],
                        zoom: 11,
                      }
                    : defaultState
                }
              >
                {marks?.map(({ xcoordinate, ycoordinate, title, text }) => {
                  return (
                    <Placemark
                      modules={["geoObject.addon.balloon"]}
                      properties={{
                        balloonContentBody: `<div><span class="title">${title}</span> <p>${text}</p></div>`,
                      }}
                      options={{
                        preset: "islands#blackCircleDotIcon",
                        hasBalloon: true,
                      }}
                      geometry={[xcoordinate, ycoordinate]}
                    />
                  );
                })}
              </Map>
            </YMaps>
          </div>
        </div>

        <div className="dealers__shops">
          <input
            value={searchValue}
            className="dealers__search"
            placeholder="Введите название города"
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
          />

          {searchData.map(({ title, shops }) => (
            <div className="dealers__shop shop">
              <div className="shop__title">{title}</div>

              {shops.map((city) => (
                <>
                  <div
                    onClick={() => handleChooseCity(city)}
                    className="shop__city"
                  >
                    {city.title}
                  </div>
                  {currentCityId === city?._id &&
                    city?.items?.map((shop) => (
                      <div className="shop__info">
                        <div className="shop__name">{shop.title}</div>
                        <div
                          className="shop__desc"
                          dangerouslySetInnerHTML={{ __html: shop.text }}
                        />
                        <button
                          className="shop__check-map"
                          onClick={() =>
                            handleChooseShop(shop.xcoordinate, shop.ycoordinate)
                          }
                        >
                          Посмотреть на карте
                        </button>
                      </div>
                    ))}
                </>
              ))}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Shops;
