import React from 'react'

export const BtnCounter = ({value, setValue}) => {
  return (
    <button type="button" className="btn__counters">
      <button
        onClick={() => {
          if (value > 1) setValue(prev => prev - 1);
        }}
        className="btn__counter btn__counter--minus">-</button>
      <input
        type="text"
        value={value}
        className="btn__counter--value"
        readOnly={true}
        onChange={(event) => {
          setValue(+event.target.value);
          if (isNaN(+event.target.value)) {
            setValue(1);
          }
          if (+event.target.value > 999) {
            setValue(999);
          }
        }}
      />
      <button
        onClick={() => setValue(prev => {
          if (prev === 999) {
            return prev;
          }
          return prev + 1
        })}
        className="btn__counter btn__counter--plus">+</button>
    </button>
  )
}