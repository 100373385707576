import React from "react";

export const DownloadDocument = ({ file, title, isInlineBtn }) => {
  const btnClass = isInlineBtn
    ? "goods-card__order-inlineBtn"
    : "goods-card__order-btn  goods-card__order-btn--size";

  return (
    <a href={file} target="_blank" rel="noopener noreferrer" download className={btnClass}>
      {title}
    </a>
  );
};
