import React, { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router";
import Input from "../components/Forms/input";
import { CartContext } from "../context";
import FormContainer from "../components/Forms/FormContainer";
import { staticPageApi } from "../api/api";
import { setStaticPage } from "../reducers/cartReducer";
import { LOGIN_VALIDATION_SCHEMA } from "../constants/index";
import "../components/Auth/auth.scss";

const Authorization = () => {
  let history = useHistory();
  let [state, dispatch] = useContext(CartContext);

  const [responseData, setResponseData] = useState();
  const [isError, setIsError] = useState(false);

  const initialValues = {
    private_login: "",
    private_pass: "",
  };

  const onSubmit = (values) => {
    let data = new FormData();
    data.append("private_login", values.private_login);
    data.append("private_pass", values.private_pass);
    data.append("id", localStorage.getItem("staticPageId"));

    localStorage.setItem(
      `user-${localStorage.getItem("staticPageId")}`,
      values.private_login
    );

    localStorage.setItem(
      `pass-${localStorage.getItem("staticPageId")}`,
      values.private_pass
    );

    staticPageApi
      .auth(localStorage.getItem("staticPageId"), data)
      .then((response) => setResponseData(response.data));
  };

  useEffect(() => {
    if (responseData?.errorAuth?.length) {
      setIsError(true);
      sessionStorage.setItem(
        `isAuth-${localStorage.getItem("staticPageId")}`,
        "false"
      );
    } else if (responseData?.ACTIVE?.length) {
      dispatch(setStaticPage(responseData));
      sessionStorage.setItem(
        `isAuth-${localStorage.getItem("staticPageId")}`,
        "true"
      );

      history.push(`${localStorage.getItem("staticPageUrl")}`);
    }
  }, [responseData]);

  return (
    <section className="auth">
      <FormContainer
        enableReinitialize
        initialValues={initialValues}
        validationSchema={LOGIN_VALIDATION_SCHEMA}
        onSubmit={onSubmit}
        className="auth__form"
        id="authForm"
      >
        {(formik) => {
          const { isValid } = formik;
          return (
            <>
              <h2 className="auth__title">Вход</h2>
              <Input name="private_login" type="text" placeholder="Логин" />
              <Input name="private_pass" type="password" placeholder="Пароль" />
              <button
                type="submit"
                className="auth__submit"
                disabled={!isValid}
              >
                Войти
              </button>
              {isError && (
                <div className="auth__error-log">{responseData.errorAuth}</div>
              )}
            </>
          );
        }}
      </FormContainer>
    </section>
  );
};

export default Authorization;
