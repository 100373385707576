import React, { useContext, useEffect, useState } from "react";
import {
  addToCart,
  setSelectedColor,
  setSelectedWood,
  updateProperties,
  setSelectedMechanism,
} from "./../../../reducers/cartReducer";
import DetailCardContentItem from "./DetailCardContentItem";

import "./DetailCardContent.scss";
import { CartContext } from "../../../context";
import ModalColorCloth from "../../ModalColorCloth/ModalColorCloth";
import { detailCardApi, productsApi } from "../../../api/api";
import { numberWithSpaces, useFadeInAnimation } from "../../../Functions";
import { useWidthContext } from "../../../widthContext";
import {
  setDetailContent,
  setSelectedCut,
  setSelectedEquipment,
  updatePrice,
} from "../../../reducers/cartReducer";
import { InfoDesc } from "../../InfoDesc/InfoDesc";
import { DownloadDocument } from "../../DownloadDocument";
import { BtnShare } from "../../BtnShare/BtnShare";

const DetailCardContent = ({
  dataDetailOption,
  setActiveModal,
  setIsPictureModalOpen,
  setEnlargedPicture,
  setIsExample,
  isDiscount,
  isShowPrice,
}) => {
  const [state, dispatch] = useContext(CartContext);
  const [activeCloth, setActiveCloth] = useState({});
  const [activeSize, setActiveSize] = useState({});
  const [activeSleepingPlace, setActiveSleepingPlace] = useState({});
  const [activeCut, setActiveCut] = useState({});
  const [fUserId, setFuserId] = useState(state.fUserId);
  const [activeItemModal, setActiveItemModal] = useState({});
  const [activeMechanism, setActiveMechanism] = useState({});

  const { isMobile } = useWidthContext();

  const [isOpenModal, setIsOpenModal] = useState(false);

  const [activeSizeImg, setActiveSizeImg] = useState("");
  const [animationClass] = useFadeInAnimation(
    activeSize,
    activeSize.images,
    setActiveSizeImg
  );

  const [activeSleepingPlaceImg, setActiveSleepingPlaceImg] = useState("");
  const [animationClassSleepingPlace] = useFadeInAnimation(
    activeSleepingPlace,
    activeSleepingPlace.images,
    setActiveSleepingPlaceImg
  );

  const [activeCutImg, setActiveCutImg] = useState("");
  const [animationClassCutImg] = useFadeInAnimation(
    activeCut,
    activeCut.images,
    setActiveCutImg
  );

  const [activeMechanismImg, setActiveMechanismImg] = useState("");
  const [animationClassMechanismImg] = useFadeInAnimation(
    activeMechanism,
    activeMechanism.images,
    setActiveMechanismImg
  );

  const properties = state.selectedContent.properties;

  // const [contentData, setContentData] = useState({...dataDetailOption});
  useEffect(() => {
    if (!!dataDetailOption._id) {
      // setContentData({...dataDetailOption});
      dispatch(setDetailContent(dataDetailOption));
    }

    return () => dispatch(setDetailContent({}));
  }, [dataDetailOption]);

  useEffect(() => {
    const postData = {
      id: state.selectedContent.parentId,
      properties: !!state.selectedContent._id
        ? [
            ...Object.values(properties)
              .map((prop) => {
                let id;
                if (!!prop.items) {
                  prop.items.forEach((el) => {
                    if (el.selected) {
                      id = el.id;
                    }
                  });
                  return {
                    id: prop.id,
                    selectedValueId: id,
                  };
                } else {
                  return null;
                }
              })
              .filter((el) => !!el),
          ]
        : undefined,
    };

    if (postData.id && !isDiscount) {
      detailCardApi.postDetailOptions(JSON.stringify(postData)).then((res) => {
        dispatch(updatePrice(res.price));
        dispatch(updateProperties(res));
      });
    }
  }, [
    activeSize,
    activeCut,
    activeMechanism,
    activeItemModal,
    activeSleepingPlace,
  ]);

  const _addToCart = () => {
    dispatch(
      addToCart(state.selectedContent, state.selectedContent.price, fUserId)
    );
  };

  useEffect(() => {
    if (
      !!dataDetailOption.properties &&
      !!dataDetailOption.properties.clothColor
    ) {
      setActiveCloth(
        dataDetailOption.properties.clothColor.items.filter(
          (el) => el.selected
        )[0]
      );
    }

    if (
      !!dataDetailOption.properties &&
      !!dataDetailOption.properties.equipment
    ) {
      setActiveSize(
        dataDetailOption.properties.equipment.items.filter(
          (el) => el.selected
        )[0]
      );
      setActiveSizeImg(activeSize.images);
    }
    if (!!dataDetailOption.properties && !!dataDetailOption.properties.type) {
      setActiveCut(
        dataDetailOption.properties.type.items.filter((el) => el.selected)[0]
      );
      setActiveCutImg(activeCut.images);
    }
    if (
      !!dataDetailOption.properties &&
      !!dataDetailOption.properties.sleepingPlace
    ) {
      setActiveSleepingPlace(
        dataDetailOption.properties.sleepingPlace.items.filter(
          (el) => el.selected
        )[0]
      );
      setActiveSleepingPlaceImg(activeSleepingPlace.images);
    }
    if (
      !!dataDetailOption.properties &&
      !!dataDetailOption.properties.mechanism
    ) {
      setActiveMechanism(
        dataDetailOption.properties.mechanism.items.filter(
          (el) => el.selected
        )[0]
      );
    }
  }, [dataDetailOption]);

  const onGoodImgClick = (picture) => {
    setEnlargedPicture(picture);
    setIsPictureModalOpen(true);
    setIsExample(false);
  };

  return (
    <>
      {Object.keys(state.selectedContent).length > 0 && (
        <div className="goods-card">
          <ModalColorCloth
            dataSlider={activeItemModal}
            title={state.selectedContent.title}
            isOpenModal={isOpenModal}
            setIsOpenModal={setIsOpenModal}
          />
          <div className="goods-card__imgs">
            {!!state.selectedContent.gallery &&
              state.selectedContent.gallery.map((el, index) => (
                <img
                  onClick={() => onGoodImgClick(el)}
                  key={index}
                  src={el}
                  alt=""
                  className="goods-card__img"
                />
              ))}
          </div>

          <div className="goods-card__info">
            <div className="goods-card__info-wrapper">
              <h6 className="goods-card__info-category">
                {state.selectedContent.type}
              </h6>
              <h2 className="goods-card__info-title">
                {state.selectedContent.title}
              </h2>

              <div className="goods-card__desc">
                {dataDetailOption?.description}
              </div>

              {!!properties && !!properties && (
                <ul className="goods-card__list">
                  {!!properties.characteristic && (
                    <DetailCardContentItem nameOption={"Характеристики"}>
                      <div
                        className="goods-card__text"
                        dangerouslySetInnerHTML={{
                          __html: properties.characteristic,
                        }}
                      ></div>
                    </DetailCardContentItem>
                  )}

                  {!!properties?.equipment?.items && (
                    <DetailCardContentItem
                      nameOption={"Размеры и комплектации"}
                    >
                      <div className="goods-card__size">
                        {/*<h6 className="goods-card__size-name">{`${activeSize.values}`}</h6>*/}
                        {/*<h6 className="goods-card__size-name">{!!activeSize.size && activeSize.size}</h6>*/}
                        <div className="goods-card__size-wrapper">
                          <div className="goods-card__sizes--kit goods-card__sizes">
                            {properties.equipment.items.map((el) => (
                              <button
                                key={el.id}
                                className={`goods-card__size-btn goods-card__size-btn--kit ${
                                  el.selected ? "active" : ""
                                }`}
                                onClick={() => {
                                  setActiveSize(el);
                                  dispatch(
                                    setSelectedEquipment(el.id, el.value)
                                  );
                                }}
                              >
                                {el.value}
                              </button>
                            ))}
                          </div>
                          <div className="goods-card__content">
                            <img
                              src={activeSizeImg}
                              alt={activeSize.value}
                              className={`goods-card__content-img  ${animationClass}`}
                              onClick={() => {
                                setActiveItemModal(activeSize);
                                setIsOpenModal(true);
                              }}
                            />
                          </div>
                          {!!properties.equipment.file && (
                            <DownloadDocument
                              file={properties.equipment.file}
                              title="Скачать все размеры"
                            />
                          )}
                        </div>
                        <InfoDesc
                          text={
                            "На сайте представлены наиболее популярные комплектации модели. Чтобы получить больше информации, свяжитесь с нами по телефону +7(8452)244-555"
                          }
                        />
                      </div>
                    </DetailCardContentItem>
                  )}

                  {!!properties && (
                    <DetailCardContentItem
                      nameOption={"Рекомендуемые материалы"}
                    >
                      <>
                        {!!properties.clothColor.items && (
                          <div className="parameters__colors">
                            <h6 className="parameters__colors-title">
                              Обивочный материал
                            </h6>
                            <div className="parameters__colors-list">
                              {properties.clothColor?.items?.map((el) => (
                                <button
                                  key={el.id}
                                  className={`parameters__colors-item ${
                                    el.selected ? "active" : ""
                                  }`}
                                  onClick={() => {
                                    setActiveItemModal(el);
                                    setIsOpenModal(true);
                                    dispatch(setSelectedColor(el.id, el.value));
                                    // dispatch(updatePriceCloth(el.id))
                                  }}
                                >
                                  <img
                                    src={el.images}
                                    alt=""
                                    className="parameters__colors-img"
                                  />
                                </button>
                              ))}
                            </div>
                          </div>
                        )}

                        {!!properties?.woodColor?.items && (
                          <div className="parameters__wood-colors">
                            <h6 className="parameters__wood-colors-title">
                              Цвет и тип дерева
                            </h6>
                            <div className="parameters__wood-colors-list">
                              {properties.woodColor?.items?.map((el) => (
                                <button
                                  className={`parameters__wood-colors-item ${
                                    el.selected ? "active" : ""
                                  }`}
                                  key={el.id}
                                  onClick={() => {
                                    setActiveItemModal(el);
                                    setIsOpenModal(true);
                                    dispatch(setSelectedWood(el.id, el.value));
                                  }}
                                >
                                  <img
                                    src={el.images}
                                    alt=""
                                    className="parameters__wood-colors-img"
                                  />
                                </button>
                              ))}
                            </div>
                          </div>
                        )}

                        <InfoDesc
                          text={
                            "На сайте представлены наиболее популярные материалы. Чтобы получить больше информации, свяжитесь с нами по телефону +7(8452)244-555"
                          }
                        />

                        {(!!properties.clothColor.file ||
                          !!properties.woodColor.file) && (
                          <div className="parameters__download">Скачать</div>
                        )}

                        {!!properties.clothColor.file && (
                          <DownloadDocument
                            file={properties.clothColor.file}
                            title="Скачать все размеры"
                          />
                        )}

                        {!!properties.woodColor.file && (
                          <DownloadDocument
                            file={properties.woodColor.file}
                            title="Палитра"
                            isInlineBtn="true"
                          />
                        )}
                      </>
                    </DetailCardContentItem>
                  )}

                  {!!properties && !!state.selectedContent?.properties?.type && (
                    <DetailCardContentItem nameOption={"Варианты кроя"}>
                      <div className="goods-card__size">
                        {/*<h6 className="goods-card__size-name">{`${activeCut.value}`}</h6>*/}
                        <div className="goods-card__size-wrapper">
                          <div className="goods-card__sizes--kit goods-card__sizes">
                            {properties.type?.items.length > 1
                              ? properties.type?.items?.map((el) => (
                                  <button
                                    key={el.id}
                                    className={`goods-card__size-btn goods-card__size-btn--kit ${
                                      activeCut.id === el.id ? "active" : ""
                                    }`}
                                    onClick={() => {
                                      setActiveCut(el);
                                      dispatch(setSelectedCut(el.id, el.value));
                                    }}
                                  >
                                    {el.value}
                                  </button>
                                ))
                              : null}
                          </div>
                          <div className="goods-card__content">
                            <img
                              src={activeCutImg}
                              alt={activeCut.values}
                              className={`goods-card__content-img ${animationClassCutImg}`}
                            />
                          </div>
                        </div>
                        {!!properties.type.file && (
                          <DownloadDocument
                            file={properties.type.file}
                            title="Скачать все размеры"
                          />
                        )}
                      </div>
                    </DetailCardContentItem>
                  )}

                  {!!properties && !!properties?.sleepingPlace && (
                    <DetailCardContentItem nameOption={"Спальное место"}>
                      <div className="goods-card__size">
                        {/*<h6 className="goods-card__size-name">{`${activeCut.value}`}</h6>*/}
                        <div className="goods-card__size-wrapper">
                          <div className="goods-card__sizes--kit goods-card__sizes">
                            {properties.sleepingPlace.items.map((el) => (
                              <button
                                key={el.id}
                                className={`goods-card__size-btn goods-card__size-btn--kit ${
                                  activeSleepingPlace.id === el.id ? "active" : ""
                                }`}
                                onClick={() => {
                                  setActiveSleepingPlace(el);
                                  dispatch(
                                    setSelectedEquipment(el.id, el.value)
                                  );
                                }}
                              >
                                {el.value}
                              </button>
                            ))}
                          </div>
                          <div className="goods-card__content">
                            <img
                              src={activeSleepingPlaceImg}
                              alt={activeSleepingPlace.values}
                              className={`goods-card__content-img ${animationClassSleepingPlace}`}
                            />
                          </div>
                        </div>
                        {/* {!!properties?.sleepingPlace.file && (
                          <DownloadDocument
                            file={!!properties?.sleepingPlace.file}
                            title="Скачать все размеры"
                          />
                        )} */}
                      </div>
                    </DetailCardContentItem>
                  )}

                  {!!properties?.mechanism?.title && (
                    <DetailCardContentItem nameOption={"Механизм"}>
                      <div className="goods-card__size">
                        {/*<h6 className="goods-card__size-name">{`${activeCut.value}`}</h6>*/}
                        <div className="goods-card__size-wrapper">
                          <div className="goods-card__sizes--kit goods-card__sizes">
                            {properties.mechanism?.items?.map((el) => (
                              <button
                                key={el.id}
                                className={`goods-card__size-btn goods-card__size-btn--kit ${
                                  activeMechanism.id === el.id ? "active" : ""
                                }`}
                                onClick={() => {
                                  setActiveMechanism(el);
                                  dispatch(
                                    setSelectedMechanism(el.id, el.value)
                                  );
                                }}
                              >
                                {el.value}
                              </button>
                            ))}
                          </div>
                          <div className="goods-card__content">
                            <img
                              src={activeMechanismImg}
                              alt=""
                              className={`goods-card__content-img ${animationClassMechanismImg}`}
                            />
                          </div>
                          {!!properties.mechanism.file && (
                            <DownloadDocument
                              file={properties.mechanism.file}
                              title="Скачать все размеры"
                            />
                          )}
                        </div>
                      </div>
                    </DetailCardContentItem>
                  )}

                  {!!properties.download && (
                    <DetailCardContentItem nameOption={"Скачать"}>
                      <div className=" goods-card__links">
                        <a href="/" className="goods-card__link">
                          Размеры и комплектации
                        </a>
                        <a href="/" className="goods-card__link">
                          3d размер
                        </a>
                      </div>
                    </DetailCardContentItem>
                  )}
                </ul>
              )}

              {isShowPrice && (
                <>
                  <div className="goods-card__price">{`от ${numberWithSpaces(
                    state.selectedContent.price
                  )} \u20bd`}</div>
                  <button
                    className="goods-card__order-btn"
                    onClick={() => {
                      setActiveModal(true);
                      if (localStorage.getItem("fUserId") === null) {
                        productsApi
                          .getFuserId()
                          .then((data) =>
                            localStorage.getItem("fUserId") === null
                              ? localStorage.setItem("fUserId", data)
                              : ""
                          )
                          .then(() => _addToCart());
                      } else {
                        _addToCart();
                      }
                    }}
                  >
                    Добавить в заказ +
                  </button>
                </>
              )}
              <BtnShare />
              {isShowPrice &&
                <InfoDesc
                  text={
                    "Цены указаны без учета доставки до регионов. Цена действительна при отгрузке из г. Саратов."
                  }
                />
              }
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DetailCardContent;
