import React, {useContext} from 'react'

import './CartOrders.scss';
import {CartContext} from "../../../../context";
import {numberWithSpaces} from "../../../../Functions";

const CartOrders = ({dataCartItem}) => {
  const date = new Date();
  const [state, dispatch] = useContext(CartContext);

  const dateOrders = `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;


  return (
    <div className="order__list">

      {
        state.cartItems.map(el => (
          <div
            key={el._id}
            className="order__item"
          >
            <img src={el.images[0]} alt="" className="order__item-img"/>

            <div className="order__item-info">
              <div className="order__item-name">{el.title}</div>
              <div className="order__item-price">{`от ${numberWithSpaces(el.price)}₽`}</div>
            </div>
          </div>
        ))
      }


      <div className="order__total">
        <span className="order__total-date">{dateOrders}</span>
        <div className="order__total-price">{`от ${numberWithSpaces(state.totalPrice)}₽`}</div>
      </div>
    </div>
  )
}

export default CartOrders
