import React, {useContext} from 'react';

import './ModalCart.scss';
import {Link} from "react-router-dom";
import {CartContext} from "../../../context";
import {removeFromCart} from "../../../reducers/cartReducer";
import {numberWithSpaces, useFadeInModalAnimation} from "../../../Functions";

const ModalCart = ({activeModal, setActiveModal}) => {
  const [state, dispatch] = useContext(CartContext);
  const [animationModalClass, closeModal] = useFadeInModalAnimation(activeModal, setActiveModal, true);

  return (
    <>
      <div className={`modal-cart ${animationModalClass}`}>
        <button
          className="modal-cart__close"
          onClick={() => closeModal()}
        />
        <div
          className="modal-cart__title">{`В корзине ${state.cartItems.length} товар ${numberWithSpaces(state.totalPrice)} ₽`}</div>
        <div className="modal-cart__list">
          {
            state.cartItems.map((el, index) => (
              <div key={`${el._id}__${index}`} className="modal-cart__item">
                <img src={el.images[0]} alt="" className="modal-cart__item-img"/>
                <div className="modal-cart__item-text">
                  <h6 className="modal-cart__item-title">{el.title}</h6>
                  <div className="modal-cart__item-price">{`от ${numberWithSpaces(el.price)} ₽`}</div>
                </div>
                <button
                  type="button"
                  className="modal-cart__item-btn--close"
                  onClick={() => {
                    dispatch(removeFromCart(el.itemId, el.price))
                  }}
                />
              </div>
            ))
          }
        </div>
        <Link to="/cart" className="modal-cart__link">Перейти в корзину</Link>
      </div>

    </>
  );
};

export default ModalCart;