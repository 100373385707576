import React, { useContext, useEffect, useRef, useState } from "react";
import { CartContext } from "../../../context";
import {
  changeClothColor,
  changeColorWoodOptions,
  changeCutOptions,
  changeMechanismOptions,
  changeSizeOptions,
  removeFromCart,
  selectClothColor,
  selectMechanism,
  selectSize,
  selectType,
  selectWoodColor,
  setQuantity,
  updatePriceCart,
  updateProperties,
  updateTotalPrice,
} from "../../../reducers/cartReducer";
import { numberWithSpaces, useFadeInAnimation } from "../../../Functions";
import { Link } from "react-router-dom";
import { BtnCounter } from "../../BtnCounter/BtnCounter";
import { detailCardApi } from "../../../api/api";

const CartItem = ({ dataCartItem }) => {
  const [counter, setCounter] = useState(1);

  const cartItemWrap = useRef(null);
  const [detailsVisible, setDetailsVisible] = useState(false);
  const [activeCutImg, setActiveCutImg] = useState({});
  const [activeSize, setActiveSize] = useState({});
  const [activeColor, setActiveColor] = useState({});
  const [activeColorWood, setActiveColorWood] = useState({});
  const [activeMechanism, setActiveMechanism] = useState({});
  const [state, dispatch] = useContext(CartContext);
  // const [isDelItem, setIsDelItem] = useState(false);
  const { type, clothColor, woodColor, equipment, mechanism } =
    !!dataCartItem.properties ? dataCartItem.properties : {};

  //animate
  const [activeColorImgCloth, setActiveColorImgCloth] = useState("");
  const [activeColorImgCut, setActiveColorImgCut] = useState("");
  const [activeColorImgWood, setActiveColorImgWood] = useState("");
  const [activeColorImgEquipment, setActiveColorImgEquipment] = useState("");
  const [activeColorImgMechanism, setActiveColorImgMechanism] = useState("");
  const [animationClassType] = useFadeInAnimation(
    activeCutImg,
    activeCutImg.images,
    setActiveColorImgCut
  );
  const [animationClassCloth] = useFadeInAnimation(
    activeColor,
    activeColor.images,
    setActiveColorImgCloth
  );
  const [animationClassWood] = useFadeInAnimation(
    activeColorWood,
    activeColorWood.images,
    setActiveColorImgWood
  );
  const [animationClassEquipment] = useFadeInAnimation(
    activeSize,
    activeSize.images,
    setActiveColorImgEquipment
  );
  const [animationClassMechanism] = useFadeInAnimation(
    activeMechanism,
    activeMechanism.images,
    setActiveColorImgMechanism
  );

  const _removeFromCart = () => {
    dispatch(removeFromCart(dataCartItem.itemId, dataCartItem.price, counter));
  };

  useEffect(() => {
    if (Object.keys(dataCartItem).length > 0) {
      !!type?.items &&
        type.items.map((el) => (el.selected ? setActiveCutImg(el) : ""));
      !!clothColor?.items &&
        clothColor.items.map((el) => (el.selected ? setActiveColor(el) : ""));
      !!woodColor?.items &&
        woodColor.items.map((el) =>
          el.selected ? setActiveColorWood(el) : ""
        );
      !!equipment?.items &&
        equipment.items.map((el) => (el.selected ? setActiveSize(el) : ""));
      !!mechanism?.items &&
        mechanism.items.map((el) =>
          el.selected ? setActiveMechanism(el) : ""
        );
    }
  }, [dataCartItem]);

  const updateQuantity = () => {
    dispatch(setQuantity(dataCartItem.itemId, counter, dataCartItem.price));
    dispatch(updateTotalPrice());
  };

  useEffect(() => {
    updateQuantity();
  }, [counter]);

  useEffect(() => {
    const postData = {
      id: dataCartItem.parentId,
      properties: !!dataCartItem.parentId
        ? [
            ...Object.values(dataCartItem.properties)
              .map((prop) => {
                let id;
                if (!!prop.items) {
                  prop.items.forEach((el) => {
                    if (el.selected) {
                      id = el.id;
                    }
                  });
                  return {
                    id: prop.id,
                    selectedValueId: id,
                  };
                } else {
                  return null;
                }
              })
              .filter((el) => !!el),
          ]
        : undefined,
    };

    if (postData.id) {
      detailCardApi.postDetailOptions(JSON.stringify(postData)).then((res) => {
        dispatch(updatePriceCart(postData.id, res.price, counter));
        dispatch(updateTotalPrice());
        dispatch(updateProperties(res));
      });
    }
  }, [dataCartItem.properties]);

  return (
    <>
      {Object.keys(dataCartItem).length > 0 && (
        <div className="cart__item-wrap">
          <div className="cart__item">
            <Link
              to={`/detailCard/${dataCartItem.parentId}/${dataCartItem.title}`}
            >
              <img
                src={dataCartItem.images[0]}
                alt=""
                className="cart__item-img"
              />
            </Link>
            <div className="cart__item-content">
              <Link
                to={`/detailCard/${dataCartItem.parentId}/${dataCartItem.title}`}
                className="cart__item-title"
              >
                {dataCartItem.title}
              </Link>
              {!!dataCartItem.properties && (
                <div className="cart__desc">
                  {!!dataCartItem.properties.clothColor?.value && (
                    <div className="cart__desc-item">
                      <span className="desc__title">Обивочный материал</span>
                      <span className="desc__text">
                        {dataCartItem.properties.clothColor?.value}
                      </span>
                    </div>
                  )}
                  {!!dataCartItem.properties.woodColor?.value && (
                    <div className="cart__desc-item">
                      <span className="desc__title">Цвет и тип дерева</span>
                      <span className="desc__text">
                        {dataCartItem.properties.woodColor?.value}
                      </span>
                    </div>
                  )}
                  {!!dataCartItem.properties.equipment?.value && (
                    <div className="cart__desc-item">
                      <span className="desc__title">Комплектация</span>
                      <span className="desc__text">
                        {dataCartItem.properties.equipment?.value}
                      </span>
                    </div>
                  )}
                  {!!dataCartItem.properties.mechanism?.value && (
                    <div className="cart__desc-item">
                      <span className="desc__title">Механизм</span>
                      <span className="desc__text">
                        {dataCartItem.properties.mechanism?.value}
                      </span>
                    </div>
                  )}
                </div>
              )}

              <BtnCounter value={counter} setValue={setCounter} />

              <div className="cart__item-price">
                от {numberWithSpaces(dataCartItem.totalPrice)} &#8381;
              </div>
            </div>
            <button
              className="cart__item--close"
              onClick={() => _removeFromCart()}
            />
          </div>

          {!!dataCartItem.properties && (
            <div className="cart__item-details details">
              <h6 className="details__title">Подробнее</h6>
              <button
                className={`details__btn ${detailsVisible ? "active" : ""}`}
                onClick={() => setDetailsVisible(!detailsVisible)}
              />

              <div
                className={`details__info ${detailsVisible ? "active" : ""}`}
                style={{
                  height: detailsVisible
                    ? cartItemWrap.current.offsetHeight
                    : "0",
                  borderBottom: detailsVisible
                    ? "border-bottom: 1px solid #333"
                    : "",
                }}
              >
                <div ref={cartItemWrap} className="details__info-wrapper">
                  <div className="details__info-item info-item">
                    <h5 className="info-item__title">Модель</h5>
                    <div className="cart__desc">
                      <div className="cart__desc-item">
                        <span className="desc__title">Название:</span>
                        <span className="desc__text">{dataCartItem.title}</span>
                      </div>
                    </div>
                  </div>

                  {!!dataCartItem.properties.type?.items && (
                    <div className="details__info-item info-item info-item--tab">
                      <h5 className="info-item__title">Варианты кроя</h5>
                      <div className="info-item__wrapper">
                        <div className="info-item__options options">
                          {dataCartItem.properties.type.items.map((el) => (
                            <button
                              key={el.id}
                              className={`options__btn opiton__btn--type ${
                                el.selected ? "active" : ""
                              }`}
                              onClick={() => {
                                dispatch(
                                  changeCutOptions(dataCartItem.itemId, el.id)
                                );
                                dispatch(
                                  selectType(dataCartItem.itemId, el.value)
                                );
                                setActiveCutImg(el.images);
                              }}
                            >
                              {el.value}
                            </button>
                          ))}
                        </div>
                        <div className="info-item__content">
                          <img
                            src={activeColorImgCut}
                            alt=""
                            className={`info-item__content-img info-item__content-img--type ${animationClassType}`}
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  {!!dataCartItem.properties.clothColor?.items && (
                    <div className="details__info-item info-item info-item--tab">
                      <h5 className="info-item__title">Обивочный материал</h5>
                      <div className="info-item__wrapper">
                        <div className="info-item__btns">
                          <div className="info-item__colors">
                            <h6 className="info-item__colors-title">
                              {activeColor.value}
                            </h6>
                            <div className="info-item__colors-list">
                              {dataCartItem.properties.clothColor.items.map(
                                (el) => (
                                  <button
                                    key={el.id}
                                    className={`info-item__colors-item ${
                                      el.selected ? "active" : ""
                                    }`}
                                    onClick={() => {
                                      dispatch(
                                        changeClothColor(
                                          dataCartItem.itemId,
                                          el.id
                                        )
                                      );
                                      dispatch(
                                        selectClothColor(
                                          dataCartItem.itemId,
                                          el.value
                                        )
                                      );
                                      setActiveColor(el);
                                    }}
                                  >
                                    <img
                                      src={el.images}
                                      alt=""
                                      className="info-item__colors-img"
                                    />
                                  </button>
                                )
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="info-item__content--cloth">
                          <img
                            src={activeColorImgCloth}
                            alt=""
                            className={`info-item__content-img info-item__content-img--cloth ${animationClassCloth}`}
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  {dataCartItem.properties.woodColor?.items && (
                    <div className="details__info-item info-item info-item--tab">
                      <h5 className="info-item__title">Цвет и тип дерева</h5>
                      <div className="info-item__wrapper">
                        <div className="info-item__wood-colors">
                          <h6 className="info-item__wood-colors-title">
                            {activeColorWood.value}
                          </h6>
                          <div className="info-item__wood-colors-list">
                            {dataCartItem.properties.woodColor.items.map(
                              (el) => (
                                <button
                                  key={el.id}
                                  className={`info-item__wood-colors-item ${
                                    el.selected ? "active" : ""
                                  }`}
                                  onClick={() => {
                                    dispatch(
                                      changeColorWoodOptions(
                                        dataCartItem.itemId,
                                        el.id
                                      )
                                    );
                                    dispatch(
                                      selectWoodColor(
                                        dataCartItem.itemId,
                                        el.value
                                      )
                                    );
                                    setActiveColorWood(el);
                                  }}
                                >
                                  <img
                                    src={el.images}
                                    alt=""
                                    className="info-item__wood-colors-img"
                                  />
                                </button>
                              )
                            )}
                          </div>
                        </div>
                        <div className="info-item__content--wood">
                          <img
                            src={activeColorImgWood}
                            alt=""
                            className={`info-item__content-img info-item__content-img--wood ${animationClassWood}`}
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  {!!dataCartItem.properties.equipment?.items && (
                    <div className="details__info-item info-item info-item--tab">
                      <h5 className="info-item__title">Комплектация</h5>
                      {/*<h6 className="info-item__name">{`${activeSize.value}`}</h6>*/}
                      {/*<h6 className="info-item__name">{!!activeSize.size && activeSize.size}</h6>*/}
                      <div className="info-item__wrapper">
                        <div className="info-item__options--kit options">
                          {dataCartItem.properties.equipment.items.map((el) => (
                            <button
                              key={el.id}
                              className={`options__btn options__btn--kit ${
                                el.selected ? "active" : ""
                              }`}
                              onClick={() => {
                                dispatch(
                                  changeSizeOptions(dataCartItem.itemId, el.id)
                                );
                                dispatch(
                                  selectSize(dataCartItem.itemId, el.value)
                                );

                                setActiveSize(el);
                              }}
                            >
                              {el.value}
                            </button>
                          ))}
                        </div>
                        <div className="info-item__content">
                          <img
                            src={activeColorImgEquipment}
                            alt=""
                            className={`info-item__content-img info-item__content-img--kit ${animationClassEquipment}`}
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  {!!dataCartItem.properties.mechanism?.items && (
                    <div className="details__info-item info-item info-item--tab">
                      <h5 className="info-item__title">Механизм</h5>
                      <div className="info-item__wrapper">
                        <div className="info-item__options--mechanism options">
                          {dataCartItem.properties.mechanism.items.map((el) => (
                            <button
                              key={el.id}
                              className={`options__btn options__btn--mechanism ${
                                el.selected ? "active" : ""
                              }`}
                              onClick={() => {
                                dispatch(
                                  changeMechanismOptions(
                                    dataCartItem.itemId,
                                    el.id
                                  )
                                );
                                dispatch(
                                  selectMechanism(dataCartItem.itemId, el.value)
                                );
                                setActiveMechanism(el);
                              }}
                            >
                              {el.value}
                            </button>
                          ))}
                        </div>
                        <div className="info-item__content--mechanism">
                          <img
                            src={activeColorImgMechanism}
                            alt=""
                            className={`info-item__content-img info-item__content-img--mechanism ${animationClassMechanism}`}
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  <button
                    type="button"
                    className={`cart__documents--close ${
                      detailsVisible ? "active" : ""
                    }`}
                    onClick={() => setDetailsVisible(false)}
                  >
                    Свернуть -
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default CartItem;
