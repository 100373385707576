import React, {useEffect, useState} from 'react'

import Header from '../components/Header/Header';
import CollectionPage from '../components/CollectionPage/CollectionPage';
import Footer from '../components/Footer/Footer';
import CircleToTop from '../components/CircleToTop/CircleToTop';
import Preloader from "../components/Preloader/Preloader";
import {Helmet} from "react-helmet";

const Collection = ({match}) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })

    if (!isLoaded) {
      document.body.style.overflow = 'hidden';
    }

  }, []);

  useEffect(() => {
    if (isLoaded) {
      document.body.style.overflow = '';
    }
  }, [isLoaded]);

  return (
    <>
      <Helmet>
        <title>Коллекции</title>
        <meta name="description" content="evanty"/>
      </Helmet>

      <Preloader isLoaded={isLoaded}/>
      <div className="container">
        <Header color={'black'}/>
      </div>

      <div className="container">
        <CollectionPage setIsLoaded={setIsLoaded} match={match}/>
      </div>

      <CircleToTop/>
      <Footer/>
    </>
  )
}

export default Collection
