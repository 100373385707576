import React from 'react'
import {Swiper, SwiperSlide} from 'swiper/react';

import './Production.scss';
// Import Swiper styles
import 'swiper/swiper.scss';
import {Link} from "react-router-dom";
import {useWidthContext} from "../../../widthContext";


const Production = ({dataProduction}) => {
  const {isMobile} = useWidthContext();


  return (
    <div className="product">
      <h2 className="product__title">Продукция</h2>
      <div className="product-wrap">
        {
          dataProduction.length > 0 && (
            <>
              {
                isMobile ? (
                  dataProduction.map((el, index) => (
                      <Link to={`/production/${el._id}`} key={el._id} className="product__item">
                        <img src={el.image} alt={el.title} className="product__item-img"/>
                        <h5 className="product__item-title">{el.title}</h5>
                        <span className="product__item-link">Подробнее</span>
                      </Link>
                    )
                  )
                ) : (
                  <Swiper
                    className={'product__slider'}
                    spaceBetween={20}
                    slidesPerView={'auto'}
                    // width={488}
                    onTouchMove={(swiper) => swiper.el.style.transform = 'scale(0.93)'}
                    onTouchEnd={(swiper) => swiper.el.style.transform = 'scale(1)'}
                    onInit={(swiper) => dataProduction.length !== 0 ? swiper.update() : ''}
                    // onSlideChange={lastSlideFix}
                  >
                    {

                      dataProduction.map(el => (
                          <SwiperSlide key={el._id} style={{width: 'auto'}}>
                            <Link to={`/production/${el._id}`} className="product__item">
                              <img src={el.image} alt={el.title} className="product__item-img"/>
                              <h5 className="product__item-title">{el.title}</h5>
                              <span className="product__item-link">Подробнее</span>
                            </Link>
                          </SwiperSlide>
                        )
                      )
                    }
                  </Swiper>
                )
              }
            </>
          )
        }
      </div>

    </div>
  )
}

export default Production
