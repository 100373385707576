import React, {useEffect, useRef, useState} from 'react'
import {useWidthContext} from "../../../widthContext";

const VacancyItem = ({dataVacancyItem, setIsOpenModal, modalEl, setVacancyId}) => {

  const [isOpen, setIsOpen] = useState(false);
  const [itemHeight, setItemHeight] = useState(0);

  const {isMobile} = useWidthContext();

  const desc = useRef('');

  useEffect(() => {
    setItemHeight(desc.current.clientHeight + 34);
  }, [isOpen])

  const styleDescOpen = {
    height: `${isOpen ? itemHeight : 0}px`,
    paddingTop: `${isOpen ? isMobile ? 35 : 75 : 0}px`, ///????????????????????????????
    opacity: `${isOpen ? 1 : 0}`,
  }


  return (
    <li className="vacancy__item">
      <h6 className="vacancy__item-title">{dataVacancyItem.title}</h6>
      <button
        className={`vacancy__item-btn ${isOpen ? 'active' : ''}`}
        onClick={() => setIsOpen(!isOpen)}
      />
      <div
        className="vacancy__desc"
        style={styleDescOpen}
      >
        <div
          ref={desc}
          className="desc-wrap"
        >
          <div
            className="vacancy__desc-content"
            dangerouslySetInnerHTML={{__html: dataVacancyItem.content}}
          />
          {/*<div className="vacancy__desc-experience">*/}
          {/*  <span>Требуемый опыт работы: {dataVacancyItem.info?.experience}</span>*/}
          {/*  <span>{dataVacancyItem.info?.typeJob}</span>*/}
          {/*</div>*/}

          {/*<div className="vacancy__desc-duty duty">*/}
          {/*  <div className="duty__title">Обязанности:</div>*/}
          {/*  <ul className="duty__list">*/}
          {/*    {*/}
          {/*      dataVacancyItem.info?.duty.map((el, index) => (*/}
          {/*        <li key={`${el}_${index}`} className="duty__item">{el}</li>*/}
          {/*      ))*/}
          {/*    }*/}
          {/*  </ul>*/}
          {/*</div>*/}

          {/*<div className="vacancy__desc-duty duty">*/}
          {/*  <div className="duty__title">Требования:</div>*/}
          {/*  <ul className="duty__list">*/}
          {/*    {*/}
          {/*      dataVacancyItem.info?.demand.map((el, index) => (*/}
          {/*        <li key={`${el}_${index}`} className="duty__item">{el}</li>*/}
          {/*      ))*/}
          {/*    }*/}
          {/*  </ul>*/}
          {/*</div>*/}

          {/*<div className="vacancy__desc-duty duty">*/}
          {/*  <div className="duty__title">График:</div>*/}
          {/*  <ul className="duty__list">*/}
          {/*    {*/}
          {/*      dataVacancyItem.info?.graph.map((el, index) => (*/}
          {/*        <li key={`${el}_${index}`} className="duty__item">{el}</li>*/}
          {/*      ))*/}
          {/*    }*/}
          {/*  </ul>*/}
          {/*</div>*/}

          <button
            type="button"
            className="vacancy__submit"
            onClick={() => {
              setIsOpenModal(true);
              setVacancyId(dataVacancyItem._id);
            }}
          >Откликнуться
          </button>
        </div>

      </div>
    </li>
  )
}

export default VacancyItem
