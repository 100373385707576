import React, {useState} from 'react'
import {Field, Formik} from 'formik';

import './FeedbackForm.scss';
import {validateFormHowOrder} from "../../../Functions";
import {howOrder} from "../../../api/api";
import ModalSuccess from "../../ModalVacancy/ModalSuccess";

const FeedbackForm = () => {
  const [isSuccessModal, setIsSuccessModal] = useState(false);
  return (
    <>
      <ModalSuccess isSuccessModal={isSuccessModal} setIsSuccessModal={setIsSuccessModal}/>
      <div className='feedback'>
      <div className="feedback__title">Ваши данные</div>

      <Formik
        initialValues={{
          name: '',
          phone: '',
          email: '',
          city: '',
          politic: false,
        }}
        validate={values => validateFormHowOrder(values)}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            howOrder.postHowOrder(JSON.stringify(values, null, 2));
            setSubmitting(false);
          }, 400);
        }}
      >

        {({
            values,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
            isValid,
            dirty,
            /* and other goodies */
          }) => {
          console.log(errors);
          return (
            <form
              className="feedback__form"
              onSubmit={handleSubmit}
            >
              <div className="feedback__form-item">
                <input
                  type="text"
                  className={`feedback__form-input ${errors.name}`}
                  name="name"
                  id="feedbackName"
                  placeholder="Имя"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                />
                {/*<label htmlFor="feedbackName" className="feedback__form-label">Ваше имя</label>*/}
              </div>
              <div className="feedback__form-item">
                <input
                  type="text"
                  className={`feedback__form-input ${errors.phone}`}
                  name="phone"
                  id="feedbackphone"
                  placeholder="Телефон"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.phone}
                />
                {/*<label htmlFor="feedbackphone" className="feedback__form-label">+7 (900) 000 00-00</label>*/}

              </div>

              <div className="feedback__form-item">
                <input
                  type="text"
                  className={`feedback__form-input ${errors.email}`}
                  name="email"
                  id="feedbackEmail"
                  placeholder="E-mail"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                />
                {/*<label htmlFor="feedbackEmail" className="feedback__form-label">Почта для связи с вами</label>*/}
              </div>

              <div className="feedback__form-item">
                <input
                  type="text"
                  className={`feedback__form-input ${errors.city}`}
                  name="city"
                  id="feedbackCity"
                  placeholder="Город"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.city}
                />
                {/*<label htmlFor="feedbackEmail" className="feedback__form-label">Почта для связи с вами</label>*/}

              </div>

              <div className={`feedback__politic ${errors.politic}`}>
                <Field type="checkbox" name="politic" className="feedback__politic-check" id="data-politic"/>
                <label htmlFor="data-politic" className="feedback__politic-title">
                  <a
                    href="https://evanty.ru/upload/%D0%9F%D0%BE%D0%BB%D0%B8%D1%82%D0%B8%D0%BA%D0%B0_%D0%BE%D0%BF%D0%B5%D1%80%D0%B0%D1%82%D0%BE%D1%80%D0%B0_%D0%B2_%D0%BE%D1%82%D0%BD%D0%BE%D1%88%D0%B5%D0%BD%D0%B8%D0%B8_%D0%BE%D0%B1%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D0%BA%D0%B8_%D0%BF%D0%B5%D1%80%D1%81%D0%B4%D0%B0%D0%BD%D0%BD%D1%8B%D1%85.pdf"
                    download
                  >Даю согласие на обработку моих персональных данных в соответствии с 152-ФЗ</a>
                </label>
              </div>


              <button
                type="submit"
                className="feedback__form-submit"
                onClick={() => {
                  if (isValid && dirty) {
                    handleSubmit();
                    setIsSuccessModal(true);
                    setTimeout(() => handleReset(), 300);
                  }
                }}
              >Откликнуться</button>
            </form>
          )
        }}

      </Formik>

    </div>
    </>
  )
}

export default FeedbackForm
