import React, {useEffect} from 'react';
import {Link} from "react-router-dom";

import './SuccessOrder.scss';


const SuccessOrder = () => {

  useEffect(() => {
    window.scrollTo({
      top: 0,
    })
  })

  return (
    <div className="success-order">
      <h3 className="success-order__subtitle">Предварительный заказ успешно оформлен</h3>
      <div className="success-order__desc">Наш менеджер свяжется с вами</div>

      <Link className="success-order__link" to='/'>На главную</Link>
    </div>
  );
};

export default SuccessOrder;