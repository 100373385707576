import * as Yup from "yup";

export const LOGIN_VALIDATION_SCHEMA = Yup.object().shape({
  private_login: Yup.string()
    .required("Поле обязательно")
    .typeError("Поле не должно быть числом"),
  private_pass: Yup.string()
    .required("Поле обязательно")
    .typeError("Поле не должно быть числом"),
});
