import React from 'react'

import "./CookieAlert.scss";


export const CookieAlert = ({handleClose}) => {
  return (
    <div className="cookie-alert">
      <div className="cookie-alert__text">Мы используем cookies, чтобы сайт работал корректно. Они помогают нам понять, как пользователи взаимодействуют с сайтом, чтобы улучшать его работу. Информация собирается анонимно. Нажимая кнопку ХОРОШО, вы разрешаете использование файлов cookie</div>
      <button
        className="cookie-alert__btn"
        onClick={handleClose}
      >ХОРОШО</button>
    </div>
  )
}