import React, {useEffect, useState} from 'react'
import {aboutFabricApi} from './../api/api';

import Header from '../components/Header/Header';
import AboutPagePromo from './../components/AboutPage/AboutPagePromo/AboutPagePromo';
import AboutPageSlider from './../components/AboutPage/AboutPageSlider/AboutPageSlider';
import AboutPagePeople from '../components/AboutPage/AboutPagePeople/AboutPagePeople';
import Footer from '../components/Footer/Footer';
import Preloader from "../components/Preloader/Preloader";
import Helmet from "react-helmet";

const AboutFabric = () => {
  const [dataYears, setDataYears] = useState([]);
  const [dataPeople, setDataPeople] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })

    if (!isLoaded) {
      document.body.style.overflow = 'hidden';
    }

    Promise.all([
      aboutFabricApi.getYears()
        .then(data => setDataYears(data)),
      aboutFabricApi.getInfo()
        .then(data => setDataPeople(data)),
    ])
      .then(() => {
        setIsLoaded(true);
      }, err => {
        setIsLoaded(false);
      })
  }, []);

  useEffect(() => {
    if (isLoaded) {
      document.body.style.overflow = '';
    }
  }, [isLoaded]);

  return (
    <>
      <Preloader isLoaded={isLoaded}/>

      <Helmet>
        <title>О фабрике</title>
        <meta name="description" content="Nested component"/>
      </Helmet>

      <div className="about-page">
        <div className="container">
          <Header color={'black'}/>

          <AboutPagePromo/>

          <AboutPageSlider dataYears={dataYears}/>

          <AboutPagePeople dataPeople={dataPeople}/>

          <Footer/>
        </div>
      </div>
    </>
  )
}

export default AboutFabric
