import React from "react";

import AsideItem from "./AsideItem/AsideItem";

import "./AsideMenu.scss";

const AsideMenu = ({ dataAsideMenu, activeMenuItem, setActiveMenuItem }) => {
  //
  // useEffect(() => {
  //   if (!!dataAsideMenu && dataAsideMenu.length > 0 && activeMenuItem === null) {
  //     if (!!dataAsideMenu[0].subcategories) {
  //       setActiveMenuItem(dataAsideMenu[0].subcategories[0]._id)
  //     } else {
  //       setActiveMenuItem(dataAsideMenu[0]._id);
  //     }
  //   }
  // }, [dataAsideMenu]);

  return (
    <aside className="aside-menu">
      <ul className="aside-menu__list">
        {!!dataAsideMenu &&
          dataAsideMenu.length > 0 &&
          dataAsideMenu.map((el) => (
            <AsideItem
              key={el._id}
              itemMenu={el}
              activeMenuItem={activeMenuItem}
              setActiveMenuItem={setActiveMenuItem}
            />
          ))}
      </ul>
    </aside>
  );
};

export default AsideMenu;
