import React, {useEffect, useState} from 'react'


import './Collection.scss';
import {collectionApi} from "../../api/api";
import {Link} from "react-router-dom";


const CollectionPage = ({setIsLoaded, match}) => {
  const [activeCollectionItem, setActiveCollectionItem] = useState(null);

  const [collectionCategoriesData, setCollectionCategoriesData] = useState([]);
  const [collectionItems, setCollectionItems] = useState([]);



  useEffect(() => {
    collectionApi.getCollectionCategories()
      .then(data => {
        setCollectionCategoriesData(data);
        setIsLoaded(true)
      });
  }, [])

  useEffect(() => {
    if (activeCollectionItem === null && collectionCategoriesData.length > 0) {
      if (!!match.params.itemId) {
        setActiveCollectionItem(match.params.itemId);
      } else {
        setActiveCollectionItem(collectionCategoriesData[0]._id);
      }
    }
  }, [collectionCategoriesData])

  useEffect(() => {
    if (activeCollectionItem !== null) {
      collectionApi.getCollectionItems(activeCollectionItem)
        .then(data => setCollectionItems(data));
    }
  }, [activeCollectionItem])


  return (
    <div className="collection-page">
      <h2 className="collection-page__title">Коллекции</h2>

      <div className="collection-page__list">
        <div className="collection-page__list-btn">
          {
            collectionCategoriesData.map(el => (
              <button
                key={el._id}
                type="button"
                className={`collection-page__btn ${activeCollectionItem === el._id ? 'active' : ''}`}
                onClick={() => setActiveCollectionItem(el._id)}
              >{el.title}</button>
            ))
          }
        </div>
      </div>

      <div className="collection-page__list-wrapper">
        {
          collectionItems.length > 0 && (
            collectionItems.map((el) => (
              <Link
                key={el._id}
                className="collection-page__item"
                to={`/detailCollection/${el._id}/${el._code ? el._code : el.title}`}
              >
                <img src={el.image} alt={el.title} className="collection-page__item-img"/>
                <div className="collection-page__item-name">{el.title}</div>
              </Link>
            ))
          )

        }
      </div>
    </div>
  )
}

export default CollectionPage
