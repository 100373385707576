import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import { isMobile, initNav } from "../../constants";

import "../SliderAll/SliderAll.scss";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";

const ExamplesSlider = ({
  dataNewsProduct,
  isNavigationMobile,
  setEnlargedPicture,
  setIsPictureModalOpen,
  setIsExample,
}) => {
  if (isMobile) {
    SwiperCore.use([Navigation]);
  }

  return (
    <>
      {dataNewsProduct.length > 0 && (
        <Swiper
          className="new-product__slider"
          spaceBetween={isMobile ? 10 : 20}
          onInit={(swiper) =>
            dataNewsProduct.length !== 0 ? swiper.update() : ""
          }
          navigation={!!(isMobile && isNavigationMobile)}
          onSwiper={(swiper) =>
            isMobile && isNavigationMobile ? initNav(swiper) : ""
          }
          onTouchMove={(swiper) => (swiper.el.style.transform = "scale(0.93)")}
          onTouchEnd={(swiper) => (swiper.el.style.transform = "scale(1)")}
          slidesPerView={"auto"}
        >
          {dataNewsProduct.map((el, index) => (
            <SwiperSlide
              key={`${el._id}__${index}`}
              style={{ width: "auto", marginRight: isMobile ? 10 : 20 }}
              onClick={() => {
                setEnlargedPicture(el.image);
                setIsPictureModalOpen(true);
                setIsExample(true);
              }}
            >
              <div className="new-product__item">
                <img src={el.image} alt="" className="new-product__item-img" />
                <h5 className="new-product__item-title">{el.title}</h5>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </>
  );
};

export default ExamplesSlider;
