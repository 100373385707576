import React, {useEffect, useState} from 'react'
import {Swiper, SwiperSlide} from 'swiper/react';


import './AboutPageSlider.scss';
import 'swiper/swiper.scss';
import {aboutFabricApi} from "../../../api/api";
import {useWidthContext} from "../../../widthContext";

const AboutPageSlider = ({dataYears}) => {
  const {isMobile} = useWidthContext();
  const [activeYear, setActiveYear] = useState(null);
  const [activeYearData, setActiveYearData] = useState({});
  const [refSw, setRefSw] = useState(null);


  useEffect(() => {
    if (activeYear !== null) {
      aboutFabricApi.getYearContent(activeYear)
        .then(data => setActiveYearData(data));
    }
  }, [activeYear])

  useEffect(() => {
    if (activeYear === null && dataYears.length > 0) {
      setActiveYear(dataYears[0]._id)
    }
  }, [dataYears])


  return (
    <div className="about-page__slider">

      {
        dataYears.length > 0 && (
          <>
            <Swiper
              className='about-page__years'
              spaceBetween={isMobile ? 50 : 100}
              slidesPerView={'auto'}
              onInit={(swiper) => dataYears.length !== 0 ? (swiper.update(), setRefSw(swiper)) : ''}
            >
              {
                dataYears.map((el, index) => (
                  <SwiperSlide
                    style={{width: 'auto'}}
                    key={el._id}
                    className={`about-page__year ${activeYear === el._id ? 'active' : ''}`}
                    onClick={() => {
                      setActiveYear(el._id);
                      refSw.slideTo(index - 1);
                    }}
                  >{el.title}</SwiperSlide>
                ))
              }
            </Swiper>

            <div className="about-page__box">
              {
                activeYearData.active === activeYear && (
                  <div key={activeYearData._id} className="about-page__content">
                    <div className="about-page__content-wrapper">
                      <h4 className="about-page__content-title">{activeYearData.title}</h4>
                      <p className="about-page__content-desc" dangerouslySetInnerHTML={{__html: activeYearData.text}}/>
                    </div>
                    {activeYearData.image &&
                    <img src={activeYearData.image} alt="" className="about-page__content-img"/>}
                  </div>
                )
              }
            </div>
          </>
        )

      }

    </div>
  )
}

export default AboutPageSlider
