import React from 'react'

import './VacancyWork.scss';
import {Link} from "react-router-dom";

const VacancyWork = () => {
  return (
    <>
      <div className="vacancy-work__text">
        <h5 className="vacancy-work__title">Работа у нас </h5>
        <p className="vacancy-work__desc">
          Создавать комфортную и&nbsp;красивую мебель может только компания, в&nbsp;которой царит уют и&nbsp;гармония. Для этого мы&nbsp;собрали команду, где каждый на&nbsp;своем месте и&nbsp;с&nbsp;душой относится к&nbsp;общему делу. Нам важно пригласить к&nbsp;себе специалиста, который болеет за&nbsp;свой труд, уважает усилия коллег и&nbsp;каждый день стремиться стать лучше. Мы&nbsp;ищем тех, кто нацелен на&nbsp;длительное партнерство, а&nbsp;не&nbsp;на&nbsp;краткосрочный заработок.
          <Link to={'/aboutFabric'}>о фабрике</Link>
        </p>
      </div>

      <div className="vacancy-work__way">
        <h4 className="vacancy-work__way-title vacancy-work__way-title--active"><span>Стабильная зарплата</span></h4>
        <h4 className="vacancy-work__way-title"><span>Официальное трудоустройстиво</span></h4>
        <h4 className="vacancy-work__way-title"><span>Дружный коллектив</span></h4>
      </div>
    </>
  )
}

export default VacancyWork
