import React from 'react'
import {Swiper, SwiperSlide} from 'swiper/react';
import SwiperCore, {Navigation} from 'swiper';
import {initNav} from './../../../constants';


import './AboutPagePeople.scss';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import {useWidthContext} from "../../../widthContext";

const AboutPagePeople = ({dataPeople}) => {
  const {isMobile} = useWidthContext();


  SwiperCore.use([Navigation]);

  return (
    <>
      <div
        className="about-page__people"
      />
      <h4 className="about-page__people-title"
          dangerouslySetInnerHTML={{__html: 'Мы&nbsp;дарим добро, красоту, комфорт, создавая мебель для тех, кто стремится к&nbsp;лучшему.'}}/>
      <div className="about-page__people-wrapper">
        <img src={require("./../../../assets/img/people-desc.png")} alt="" className="about-page__people-img"/>
        <div className="about-page__people-img-desc">
          Дружный коллектив Эванти
          <span>Фабрика 2018 г.</span>
        </div>
      </div>

      <div className="about-page__people-desc people-desc">
        <div className="people-desc__wrapper">
          <h4 className="people-desc__title">Мы коллектив мф evanty</h4>
          <p className="people-desc__text">Мы делаем мебель, как для своей семьи: из экологически чистых материалов,
            надежную, в русском стиле.</p>
          <p className="people-desc__text">Всю нашу мебель мы изготавливаем вручную. Только вручную можно добиться
            тщательной проработки всех деталей. А это значит - продукция не только качественная, но и эксклюзивная,
            второй такой нет ни у кого в мире.</p>
          {
            !isMobile && (
              <div className="people-desc__name">Федоров Юрий Петрович</div>

            )
          }
        </div>
        <img src={require("./../../../assets/img/people-f.png")} alt="" className="people-desc__img"/>
        {
          isMobile && (
            <div className="people-desc__name">Федоров Юрий Петрович</div>
          )
        }
      </div>


      {
        !!dataPeople.personal && (
          <Swiper
            className='about-page__people-slider people-slider'
            spaceBetween={isMobile ? 20 : 100}
            slidesPerView={'auto'}
            onInit={(swiper) => dataPeople.length !== 0 ? swiper.update() : ''}
            navigation
            onSwiper={(swiper) => initNav(swiper)}
          >
            {
              dataPeople.personal.map(el => (
                <SwiperSlide
                  key={el.id}
                  className='people-slider__item'
                >
                  <img src={el.image} alt="" className="people-slider__item-img"/>
                  <div className="people-slider__item-wrapper">
                    <h5 className="people-slider__item-name">{el.title}</h5>
                    {/*<div className="people-slider__item-position">{el.position}</div>*/}
                    <p className="people-slider__item-text" dangerouslySetInnerHTML={{__html: el.text}}/>
                  </div>
                </SwiperSlide>
              ))
            }


          </Swiper>
        )
      }

      <div className="video">
        <iframe src={dataPeople.video} frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen/>

      </div>
    </>
  )
}

export default AboutPagePeople
