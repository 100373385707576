import React, { useEffect, useState, useContext } from "react";
import { staticPageApi } from "../../api/api";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router";
import { CartContext } from "../../context.js";
import Preloader from "../Preloader/Preloader";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

import "./index.scss";

export const StaticPage = ({ idPage, url }) => {
  let history = useHistory();
  let [state] = useContext(CartContext);

  const [detailPageInfo, setDetailPageInfo] = useState();
  const [responseData, setResponseData] = useState();
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    staticPageApi.getStaticPageDetail(idPage).then((res) => {
      setDetailPageInfo(res);
      setIsLoaded(true);
    });

    localStorage.setItem("staticPageId", idPage);
    localStorage.setItem("staticPageUrl", url);

    let data = new FormData();
    data.append("private_login", `${localStorage.getItem(`user-${idPage}`)}`);
    data.append("private_pass", `${localStorage.getItem(`pass-${idPage}`)}`);

    staticPageApi
      .auth(idPage, data)
      .then((response) => setResponseData(response.data));
  }, []);

  useEffect(() => {
    if (
      detailPageInfo?.auth &&
      sessionStorage.getItem(`isAuth-${idPage}`) !== "true"
    ) {
      history.push("/auth");
    }
  }, [detailPageInfo]);

  useEffect(() => {
    if (isLoaded) {
      document.body.style.overflow = "";
    }
  }, [isLoaded]);

  return (
    <>
      <Preloader isLoaded={isLoaded} />

      <Helmet>
        <title>{state.staticPage?.title}</title>
      </Helmet>

      <div className="how-order">
        <div className="container">
          <Header color={"black"} />

          <div className="how-order__content">
            <div dangerouslySetInnerHTML={{ __html: responseData?.text }} />
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};
