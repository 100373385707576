import React, { useContext, useEffect, useState } from "react";
import { productsApi } from "./../api/api.js";

import Header from "../components/Header/Header";
import ProductsModel from "../components/ProductsModel/ProductsModel";
import AsideMenu from "../components/AsideMenu/AsideMenu";
import Footer from "../components/Footer/Footer";
import CircleToTop from "./../components/CircleToTop/CircleToTop";
import Preloader from "../components/Preloader/Preloader";
import { Helmet } from "react-helmet";
import { CartContext } from "../context";
import { setProductionCardСoordinates } from "../reducers/cartReducer";

const Production = ({ match, history }) => {
  const [dataProductsItems, setDataProductsItems] = useState([]);
  const [dataAsideMenu, setDataAsideMenu] = useState([]);
  const [activeMenuItem, setActiveMenuItem] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [state, dispatch] = useContext(CartContext);

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
    productsApi.getAsideMenuItems().then((data) => setDataAsideMenu(data));

    if (!match.params.itemId) {
      productsApi.getProductionItems(32).then((data) => {
        setDataProductsItems(data);
        setIsLoaded(true);
        setActiveMenuItem("32");
      });
    }

    if (!isLoaded) {
      document.body.style.overflow = "hidden";
    }
  }, []);

  useEffect(() => {
    if (!!match.params.itemId) {
      setActiveMenuItem(match.params.itemId);
    }
  }, [match.params]);

  useEffect(() => {
    if (activeMenuItem !== null) {
      window.scrollTo({
        top: 0,
        behavior: "instant",
      });
      productsApi.getProductionItems(activeMenuItem).then((data) => {
        setDataProductsItems(data);
        history.push(`/production/${activeMenuItem}/${data.title}`);
        setIsLoaded(true);
      });
    }
  }, [activeMenuItem]);

  useEffect(() => {
    if (isLoaded) {
      document.body.style.overflow = "";

      window.scrollTo({
        top: state.productionCardСoordinates
          ? state.productionCardСoordinates
          : 0,
      });

      dispatch(setProductionCardСoordinates(0));
    }
  }, [isLoaded]);

  return (
    <>
      <Preloader isLoaded={isLoaded} />

      <Helmet>
        <title>Продукция</title>
      </Helmet>

      <div className="container">
        <Header color={"black"} />
      </div>

      <section className="main-product-page">
        <div className="container">
          <section className="products-model">
            <h2 className="products-model__title">Продукция</h2>
            <div className="products-model__wrapper">
              <ProductsModel dataProductsItems={dataProductsItems} />
              <AsideMenu
                dataAsideMenu={dataAsideMenu}
                activeMenuItem={activeMenuItem}
                setActiveMenuItem={setActiveMenuItem}
              />
            </div>
          </section>
        </div>
      </section>

      <CircleToTop />
      <Footer />
    </>
  );
};

export default Production;
