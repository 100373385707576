import React, {useContext} from 'react'

import CartItem from './CartItem';

import './CartList.scss';
import {CartContext} from "../../../context";

const CartList = () => {
  const [state] = useContext(CartContext);

  return (
    <div className="cart__list">
      <div className="cart__wrapper">
        {
          state.cartItems.map((el, index) => (
            <CartItem key={`${el._id}__${index}`} dataCartItem={el}/>
          ))
        }
      </div>
      <div className="cart__documents">
        <div className="cart__documents-wrapper">
          <button className="cart__documents--pdf" onClick={window.print}>
            <span>Сохранить в PDF</span>
            <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <line y1="15.25" x2="15" y2="15.25" stroke="#BDBDBD" strokeWidth="1.5"/>
              <path
                d="M6.46967 12.5303C6.76256 12.8232 7.23744 12.8232 7.53033 12.5303L12.3033 7.75736C12.5962 7.46447 12.5962 6.98959 12.3033 6.6967C12.0104 6.40381 11.5355 6.40381 11.2426 6.6967L7 10.9393L2.75736 6.6967C2.46447 6.40381 1.98959 6.40381 1.6967 6.6967C1.40381 6.98959 1.40381 7.46447 1.6967 7.75736L6.46967 12.5303ZM6.25 -3.27841e-08L6.25 12L7.75 12L7.75 3.27841e-08L6.25 -3.27841e-08Z"
                fill="#BDBDBD"/>
            </svg>
          </button>


          <button className="cart__documents--print" onClick={window.print}>
            <span>Печать</span>
            <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M13.6984 9.57143H15.6032M4.1746 17.4286H1V6H18.7778V17.4286H15.6032H4.1746ZM4.1746 13.1429V21H15.6032V13.1429H4.1746ZM15.6032 6V1H4.1746V6H15.6032Z"
                stroke="#BDBDBD" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </button>
        </div>
      </div>
    </div>
  )
}

export default CartList
