import React from 'react'

const CircleToTop = () => {
  return (
    <div className="main__circle"
      onClick={() => window.scrollTo({
        top: 0,
        behavior: "smooth",
      })}
    >
      <button className="main__up-page">Наверх</button>
    </div>
  )
}

export default CircleToTop
