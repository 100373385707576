import React, {useState, useRef} from 'react'

const DetailCardContentItem = ({nameOption, children}) => {
  const [visibleParameters, setVisibleParameters] = useState(false);
  const optionItem = useRef(null);

  const handleClick = (visible) => (e) => {
    setVisibleParameters(visible);
  }

  return (
    <li className={`goods-card__item ${visibleParameters ? 'active' : ''}`}>
      <h6 className="goods-card__item-title">{nameOption}</h6>
      <button className="goods-card__item-btn"
        onClick={handleClick(!visibleParameters)}
      />
      <div
        className="goods-card__parameters"
        style={{height: visibleParameters ? (optionItem.current.offsetHeight) : '0'}}
      >
        <div ref={optionItem} className="goods-card__parameters-wrapper">
          {children}
        </div>
      </div>
    </li>
  )
}

export default DetailCardContentItem
