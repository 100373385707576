import React, { useContext, useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";

import MainPage from "./pages/MainPage.js";
import Production from "./pages/Production.js";
import Collection from "./pages/Collection";
import DetailCardGoods from "./pages/DetailCardGoods";
import DetailCollection from "./pages/DetailCollection";
import Cart from "./pages/Cart";
import AboutFabric from "./pages/AboutFabric";
import Vacancy from "./pages/Vacancy";
import Dealers from "./pages/Dealers";
import HowOrder from "./pages/HowOrder";
import Cooperation from "./pages/Cooperation";
import { addScripts } from "./Functions";

import "./App.scss";
import { CartContext } from "./context";
import { setCartData } from "./reducers/cartReducer";
import NotFound from "./pages/NotFound";
import { scrollInit } from "./scroll";
import { WidthProvider } from "./widthContext";
import Discount from "./pages/Discount";
import { CookieAlert } from "./components/CookieAlert/CookieAlert";
import { staticPageApi } from "./api/api";
import { StaticPage } from "./components/StaticPage";
import Authorization from "./pages/Auth.js";
import { useStaticPage } from "./staticContext";
import NewsDetail from "./pages/NewsDetail";

function App() {
  const [state, dispatch] = useContext(CartContext);
  const [isShowCookie, setIsShowCookie] = useState(null);
  const [staticPages, setStaticPages] = useStaticPage();

  const loadStaticPage = () => {
    staticPageApi.getStaticPage().then((res) => setStaticPages(res));
  };

  useEffect(() => {
    if ((state.cartItems !== null && state.totalPrice > 0) || state.dellItem) {
      localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
      localStorage.setItem("totalPrice", JSON.stringify(state.totalPrice));
    }
  }, [state]);

  useEffect(() => {
    if (
      localStorage.getItem("cartItems") === null ||
      localStorage.getItem("cartItems").length === 0
    ) {
      localStorage.setItem("cartItems", []);
      localStorage.setItem("totalPrice", 0);
    } else {
      dispatch(setCartData());
    }
    if (localStorage.getItem("isShowCookie") === null) {
      localStorage.setItem("isShowCookie", true);
      setIsShowCookie(JSON.parse(localStorage.getItem("isShowCookie")));
    } else {
      localStorage.setItem("isShowCookie", false);
      setIsShowCookie(JSON.parse(localStorage.getItem("isShowCookie")));
    }

    scrollInit();
    addScripts();
    loadStaticPage();
  }, []);

  const handleCloseCookie = () => {
    localStorage.setItem("isShowCookie", false);
    setIsShowCookie(JSON.parse(localStorage.getItem("isShowCookie")));
  };

  return (
    !!staticPages.length && (
      <>
        <WidthProvider>
          <div className="App">
            <Switch>
              <Route exact path="/" component={MainPage} />
              <Route exact path="/production" component={Production} />
              <Route
                exact
                path="/production/:itemId/:title"
                component={Production}
              />
              <Route exact path="/production/:itemId" component={Production} />

              <Route exact path="/discount" component={Discount} />
              <Route exact path="/discount/:itemId" component={Discount} />
              <Route
                exact
                path="/discount/:itemId/:title"
                component={Discount}
              />
              <Route
                exact
                path="/discount-detail/:itemId/:title"
                render={(props) => (
                  <DetailCardGoods {...props} isDiscount={true} />
                )}
              />

              <Route exact path="/collection" component={Collection} />
              <Route exact path="/collection/:itemId/" component={Collection} />
              <Route
                exact
                path="/detailCard/:itemId/:title"
                component={DetailCardGoods}
              />

              <Route
                exact
                path="/detailCollection/:itemId/:title"
                component={DetailCollection}
              />
              <Route exact path="/cart" component={Cart} />
              <Route exact path="/aboutFabric" component={AboutFabric} />
              <Route exact path="/vacancy" component={Vacancy} />
              <Route exact path="/dealers" component={Dealers} />
              <Route exact path="/howOrder" component={HowOrder} />
              <Route exact path="/cooperation" component={Cooperation} />
              <Route exact path="/auth" component={Authorization} />

              <Route exact path="/news/:itemId" component={NewsDetail} />

              {staticPages?.map((page) => {
                if (page?.url) {
                  return (
                    <Route
                      key={`static-page--${page?._id}`}
                      exact={false}
                      path={page?.url}
                      render={(props) => (
                        <StaticPage
                          url={page?.url}
                          idPage={page._id}
                          {...props}
                        />
                      )}
                    />
                  );
                }
              })}

              <Route path="*" component={NotFound} />
            </Switch>
            {isShowCookie && <CookieAlert handleClose={handleCloseCookie} />}
          </div>
        </WidthProvider>
      </>
    )
  );
}

export default App;
