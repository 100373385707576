import React, { useEffect, useState } from "react";
import Footer from "../components/Footer/Footer";
import { eventsApi } from "./../api/api";
import Preloader from "../components/Preloader/Preloader";
import Helmet from "react-helmet";
import { Redirect } from "react-router-dom";
import Header from "../components/Header/Header";
import News from "../components/MainPage/News/News";
import { formatDate } from "../Functions";
import { useLocation } from "react-router-dom";

function handlePopState() {
  scrollToTop();
}

export function scrollToTop() {
  const element = document.documentElement || document.body;
  element.scrollTo({
    top: 0,
    behavior: "smooth",
  });
}

const NewsDetail = ({ match }) => {
  const [dataItem, setDataItem] = useState({});
  const [slider, setSlider] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isError, setIsError] = useState(false);

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    scrollToTop();
    if (!isLoaded) {
      document.body.style.overflow = "hidden";
    }
    Promise.all([
      eventsApi
        .getSlider(match.params.itemId)
        .then((data) => {
          setSlider(data);
        })
        .catch(() => setIsError(true)),
      eventsApi.getDetailNews(match.params.itemId).then((data) => {
        console.log(data);
        setDataItem(data[0]);
      }),
    ]).then(() => {
      setIsLoaded(true);
    });
  }, [match.params.itemId]);

  useEffect(() => {
    if (isLoaded) {
      document.body.style.overflow = "";
    }
  }, [isLoaded]);

  return (
    <>
      {isError && <Redirect to="/404" />}
      <Helmet>
        <title>{dataItem.title}</title>
        <meta name="description" content="Nested component" />
      </Helmet>

      <Preloader isLoaded={isLoaded} />
      <div className="news-detail">
        <div className="container">
          <Header color={"black"} />

          <div
            className="news-detail__desc"
            dangerouslySetInnerHTML={{ __html: dataItem.detailText }}
          />
        </div>
      </div>

      <div className="container">
        <News data={slider} />
      </div>

      {/*<CircleToTop />*/}

      <Footer />
    </>
  );
};
export default NewsDetail;
