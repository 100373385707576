import React from 'react'
import './Promo.scss';

const Promo = () => {
  return (
    <div className="promo__box">
      <div className="promo__content">
        <h1 className="promo__title">Делаем мебель, <br/>
          как <span>для своей семьи</span></h1>
        <div className="promo__subtitle">Мебель с душой, характером и темпераментом, надёжная, в европейском стиле</div>
      </div>
    
      <div className="promo__scroll">
        <div className="promo__scroll-line"/>
        <span className="promo__scroll-title">Скрольте вниз</span>
      </div>
    </div>
  )
}

export default Promo
