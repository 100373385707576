import React, { useContext, useEffect, useState } from "react";

import Header from "../components/Header/Header";
import DiscountModels from "./../components/DiscountModels/DiscountModels";
import AsideMenu from "../components/AsideMenu/AsideMenu";
import CircleToTop from "./../components/CircleToTop/CircleToTop";
import Footer from "../components/Footer/Footer";
import { productsApi } from "../api/api";
import Preloader from "../components/Preloader/Preloader";
import Helmet from "react-helmet";
import { CartContext } from "../context";

const Discount = ({ match, history }) => {
  const [dataAsideMenu, setDataAsideMenu] = useState([]);
  const [activeMenuItem, setActiveMenuItem] = useState(null);
  const [dataDiscountItems, setDataDiscountItems] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [state] = useContext(CartContext);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    productsApi
      .getDiscountMenu()
      .then((data) => {
        setDataAsideMenu(data);
        return data[0]._id;
      })
      .then((id) => {
        if (!match.params.itemId) {
          productsApi.getDiscountItems(id).then((data) => {
            setDataDiscountItems(data);
            setIsLoaded(true);
            setActiveMenuItem(id);
          });
        }
      });

    if (!isLoaded) {
      document.body.style.overflow = "hidden";
    }
  }, []);

  useEffect(() => {
    if (!!match.params.itemId) {
      setActiveMenuItem(match.params.itemId);
    }
  }, [match.params]);

  useEffect(() => {
    if (activeMenuItem !== null) {
      window.scrollTo({
        top: 0,
        behavior: "instant",
      });
      productsApi.getDiscountItems(activeMenuItem).then((data) => {
        setDataDiscountItems(data);
        history.push(`/discount/${activeMenuItem}/${data.title}`);
        setIsLoaded(true);
      });
    }
  }, [activeMenuItem]);

  useEffect(() => {
    if (isLoaded) {
      document.body.style.overflow = "";

      window.scrollTo({
        top: state.discountCardCoordinates || 0,
      });
    }
  }, [isLoaded]);

  return (
    <>
      <Preloader isLoaded={isLoaded} />

      <Helmet>
        <title>Дисконт</title>
        <meta name="description" content="Nested component" />
      </Helmet>

      <div className="container">
        <Header color={"black"} />
      </div>

      <section className="main-discount-page">
        <div className="container">
          <section className="discount-model">
            <h2 className="discount-model__title">Дисконт</h2>
            <div className="discount-model__wrapper">
              <DiscountModels dataDiscountItems={dataDiscountItems} />
              <AsideMenu
                dataAsideMenu={dataAsideMenu}
                activeMenuItem={activeMenuItem}
                setActiveMenuItem={setActiveMenuItem}
              />
            </div>
          </section>
        </div>
      </section>

      <CircleToTop />
      <Footer />
    </>
  );
};

export default Discount;
