import React from 'react';
import {Link} from "react-router-dom";


import "../assets/style/NotFound.scss";
import Helmet from "react-helmet";

const NotFound = () => {
  return (
    <div className="not-found">

      <Helmet>
        <title>404</title>
        <meta name="description" content="Nested component" />
      </Helmet>

      <div className="not-found__title">404</div>
      <div className="not-found__subtitle">
        Страница, которую вы ищите не найдена.
        Возможно вы попали на неправильную ссылку
      </div>
      <Link to="/" className="not-found__btn">На главную</Link>
    </div>
  );
};

export default NotFound;