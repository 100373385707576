import { useEffect, useState } from "react";

export const validateFormCart = (values, face) => {
  const errors = {};
  if (face === 0) {
    if (!values.email) {
      if (values.phone.length > 4) {
        delete errors.phone;
      } else errors.email = "error";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = "error";
    } else {
      if (values.phone.length > 4) {
        errors.email = "";
        delete errors.email;
      }
    }
    if (!values.name) {
      errors.name = "error";
    }
    if (!values.phone) {
      if (values.email.length > 4) {
        delete errors.email;
      } else errors.phone = "error";
    } else if (
      !/^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/i.test(values.phone)
    ) {
      errors.phone = "error";
    }
    if (!values.politic) {
      errors.politic = "error";
    }
  }
  return errors;
};
export const validateForm = (values) => {
  const errors = {};

  if (!values.email) {
    errors.email = "error";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
    errors.email = "error";
  }
  if (!values.name) {
    errors.name = "error";
  }
  if (!values.phone) {
    errors.phone = "error";
  } else if (
    !/^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/i.test(values.phone)
  ) {
    errors.phone = "error";
  }
  if (!values.politic) {
    errors.politic = "error";
  }
  return errors;
};

export const validateFormHowOrder = (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = "error";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
    errors.email = "error";
  }
  if (!values.name) {
    errors.name = "error";
  }
  if (!values.city) {
    errors.city = "error";
  }
  if (!values.politic) {
    errors.politic = "error";
  }
  if (!values.phone) {
    errors.phone = "error";
  } else if (
    !/^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/i.test(values.phone)
  ) {
    errors.phone = "error";
  }
  return errors;
};

export const useFadeInAnimation = (
  triggerState,
  activePic,
  setActivePicState,
  time = 300
) => {
  const [isFadeIn, setIsFadeIn] = useState("");
  const [_animationClass, _setAnimationClass] = useState("");

  useEffect(() => {
    _setAnimationClass(animationClass());
  }, [isFadeIn]);

  const animationClass = () => {
    return isFadeIn === "fadeIn"
      ? "fadeIn"
      : isFadeIn === "fadeOut"
      ? "fadeOut"
      : "";
  };

  useEffect(() => {
    setIsFadeIn("fadeOut");

    setTimeout(() => {
      setActivePicState(activePic);
      setIsFadeIn("fadeIn");
    }, time);
  }, [triggerState]);

  return [_animationClass];
};

export const useFadeInModalAnimation = (
  isModalActive,
  setModalActive,
  withDelay = false,
  delayTime = 5000,
  animationTime = 300
) => {
  const [isFadeIn, setIsFadeIn] = useState("");
  const [_animationClass, _setAnimationClass] = useState("");

  const closeModal = () => {
    setIsFadeIn("fadeOut");

    setTimeout(() => {
      document.body.style.overflow = "";
      setModalActive(false);
    }, animationTime);
  };

  useEffect(() => {
    if (withDelay) {
      if (isModalActive) {
        setIsFadeIn("fadeIn");
        setTimeout(() => {
          setIsFadeIn("fadeOut");

          setTimeout(() => {
            setModalActive(false);
          }, animationTime);
        }, delayTime);
      }
    } else {
      if (isModalActive) {
        document.body.style.overflow = "hidden";
        setIsFadeIn("fadeIn");
      }
    }
  }, [isModalActive]);

  useEffect(() => {
    _setAnimationClass(animationClass());
  }, [isFadeIn]);

  const animationClass = () => {
    return isFadeIn === "fadeIn"
      ? "fadeIn"
      : isFadeIn === "fadeOut"
      ? "fadeOut"
      : "fadeOut";
  };

  return [_animationClass, closeModal];
};

export const numberWithSpaces = (x) => {
  if (x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  } else return 0;
};

export const lastSlideFix = (swiper) => {
  if (swiper.slides.length - 1 === swiper.activeIndex) {
    swiper.slideTo(swiper.activeIndex - 1);
  }
};

export const addScripts = () => {
  fetch(`${process.env.REACT_APP_BASE_URL}/metric`)
    .then((res) => res.json())
    .then((data) => {
      data.forEach((el) => {
        const doc = new DOMParser().parseFromString(el.content, "text/html");
        const scriptTag = document.createElement("script");

        scriptTag.innerHTML = doc.querySelector("script").innerHTML;
        scriptTag.setAttribute("type", `text/javascript`);
        document.body.appendChild(scriptTag);

        eval(doc.querySelector("script").innerHTML);

        if (doc.querySelector("script[src]")) {
          const attrs = doc.querySelector("script[src]").attributes;
          for (let i = 0; i < attrs.length; i++) {
            scriptTag.setAttribute(attrs[i].nodeName, attrs[i].nodeValue);
          }
          document.body.appendChild(scriptTag);
        } else {
          eval(doc.querySelector("script").innerHTML);
        }
      });
    });
};

export function formatDate(inputDate) {
  const months = [
    "январь",
    "февраль",
    "март",
    "апрель",
    "май",
    "июнь",
    "июль",
    "август",
    "сентябрь",
    "октябрь",
    "ноябрь",
    "декабрь"
  ];

  if (inputDate) {
    const dateParts = inputDate.split(".");
    const day = parseInt(dateParts[0], 10);
    const monthNum = parseInt(dateParts[1], 10) - 1; // Месяцы в JavaScript нумеруются с 0 до 11
    const monthName = months[monthNum];

    return `${day} ${monthName}`;
  }

}
