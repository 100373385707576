import React, { useEffect, useRef, useState } from "react";
import { detailCardApi } from "./../api/api.js";
import Header from "../components/Header/Header";
import DetailCardPromo from "./../components/DetailCard/DetailCardPromo/DetailCardPromo";
import DetailCardContent from "./../components/DetailCard/DetailCardContent/DetailCardContent";
import SliderAll from "../components/SliderAll/SliderAll";
import Footer from "../components/Footer/Footer";
import Preloader from "../components/Preloader/Preloader";
import ModalCart from "../components/Сart/ModalCart/ModalCart";
import ModalPicture from "../components/ModalPicture/ModalPicture";
import BreadCrumbs from "../components/BreadCrumbs/BreadCrumbs";
import BreadCrumbsItem from "../components/BreadCrumbs/BreadCrumbsItem";
import Helmet from "react-helmet";
import { useWidthContext } from "../widthContext";
import { Redirect } from "react-router-dom";
import ExamplesSlider from "../components/ExamplesSlider/ExamplesSlider.js";
import router from "react-router-dom/es/Router";

const DetailCardGoods = ({ match, isDiscount, history }) => {
  const [dataDetailCard, setDataDetailCard] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);
  const [activeModal, setActiveModal] = useState(false);
  const [isError, setIsError] = useState(false);
  const [enlargedPicture, setEnlargedPicture] = useState("");
  const [isPictureModalOpen, setIsPictureModalOpen] = useState(false);
  const [isExample, setIsExample] = useState(false);
  const [isShowPrice, setIsShowPrice] = useState(true);
  const modalCartEl = useRef(null);
  const { isMobile } = useWidthContext();

  useEffect(() => {
    if (isDiscount) {
      detailCardApi
        .getDataDiscountItem(match.params.itemId)
        .then((data) => {
          setDataDetailCard(data);
          setIsLoaded(true);
          window.scrollTo({
            top: 0,
          });
        })
        .catch(() => setIsError(true));
    } else {
      detailCardApi
        .getDataDetailCard(match.params.itemId)
        .then((data) => {
          setDataDetailCard(data);
          setIsLoaded(true);
          setIsShowPrice(!data.hidePrice)
          window.scrollTo({
            top: 0,
          });
        })
        .catch(() => setIsError(true));
    }
    if (!isLoaded) {
      document.body.style.overflow = "hidden";
    }
  }, [match.params.itemId]);

  useEffect(() => {
    if (isLoaded) {
      document.body.style.overflow = "";
    }
  }, [isLoaded]);

  useEffect(() => {
    if (isMobile) {
      activeModal
        ? (document.body.style.overflow = "hidden")
        : (document.body.style.overflow = "");
    }
  }, [activeModal]);

  useEffect( () => {
      if (window.easytekaConnect && window.removeEasytekaElements) {
          window.removeEasytekaElements();
          window.easytekaConnect();
      }

      return ()=> window.removeEasytekaElements()
  }, [match])



  const isDiscountRoute = isDiscount ? "/discount" : "/production";

  return (
    <>
      {isError && <Redirect to="/404" />}

      <Helmet>
        <title>{dataDetailCard.title}</title>
      </Helmet>
      <Preloader isLoaded={isLoaded} />
      <ModalCart
        modalCartRef={modalCartEl}
        activeModal={activeModal}
        setActiveModal={setActiveModal}
      />
      <ModalPicture
        isPictureModalOpen={isPictureModalOpen}
        setIsPictureModalOpen={setIsPictureModalOpen}
        picture={enlargedPicture}
        isExample={isExample}
      />
      <section className="goods-card-promo">
        <div className="container">
          <div className="header__wrapper">
            <Header
              color={isMobile ? "black" : "white"}
              isBreadCrumbs={true}
              isBlackPromo={true}
            >
              <BreadCrumbs>
                <BreadCrumbsItem title={"Каталог"} path={isDiscountRoute} />
                <BreadCrumbsItem
                  title={dataDetailCard.type}
                  path={`${isDiscountRoute}/${dataDetailCard.sectionId}/${dataDetailCard.type}`}
                />
                <BreadCrumbsItem
                  title={dataDetailCard.title}
                  path={match.url}
                  isActive={true}
                />
              </BreadCrumbs>
            </Header>
          </div>
          <DetailCardPromo
            dataDetailPrev={
              dataDetailCard.images && dataDetailCard.images.length > 0
                ? dataDetailCard
                : ""
            }
          />
        </div>
      </section>
      <div className="container">
        <DetailCardContent
          modalCartRef={modalCartEl}
          setActiveModal={setActiveModal}
          dataDetailOption={dataDetailCard}
          isPictureModalOpen={isPictureModalOpen}
          setIsPictureModalOpen={setIsPictureModalOpen}
          setEnlargedPicture={setEnlargedPicture}
          setIsExample={setIsExample}
          isDiscount={isDiscount}
          isShowPrice={isShowPrice}
        />
      </div>

      {!!dataDetailCard?.examples?.length && (
        <div className="goods-collection">
          <div className="container">
            <h3 className="goods-collection__title">Примеры исполнения</h3>
            <ExamplesSlider
              dataNewsProduct={
                dataDetailCard?.examples?.length ? dataDetailCard.examples : ""
              }
              setEnlargedPicture={setEnlargedPicture}
              setIsPictureModalOpen={setIsPictureModalOpen}
              setIsExample={setIsExample}
            />
          </div>
        </div>
      )}

      {!!dataDetailCard?.goods?.length && (
        <div className="goods-collection">
          <div className="container">
            <h3 className="goods-collection__title">Рекомендуемые товары</h3>
            <SliderAll
              isSubtitle={true}
              isDetail={true}
              setIsLoaded={setIsLoaded}
              dataNewsProduct={
                dataDetailCard?.goods?.length ? dataDetailCard.goods : ""
              }
            />
          </div>
        </div>
      )}

      <Footer />
    </>
  );
};

export default DetailCardGoods;
