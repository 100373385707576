import React, {useEffect, useRef, useState} from 'react'
import Header from '../components/Header/Header';
import VacancyPromo from './../components/Vacancy/VacancyPromo/VacancyPromo';
import VacancyWork from './../components/Vacancy/VacancyWork/VacancyWork';
import VacancyList from './../components/Vacancy/VacancyList/VacancyList';
import {vacancyApi} from './../api/api';
import CircleToTop from './../components/CircleToTop/CircleToTop';
import Footer from '../components/Footer/Footer';
import Preloader from "../components/Preloader/Preloader";
import Helmet from "react-helmet";
import {useWidthContext} from "../widthContext";
import ModalVacancy from "../components/ModalVacancy/ModalVacancy";


const Vacancy = () => {
  const {isMobile} = useWidthContext();
  const [dataVacancy, setDataVacancy] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isSuccessModal, setIsSuccessModal] = useState(false);
  const [vacancyId, setVacancyId] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const modalEl = useRef(null);


  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
    vacancyApi.getVacancy()
      .then(data => {
        setIsLoaded(true);
        setDataVacancy(data)
      });

    if (!isLoaded) {
      document.body.style.overflow = 'hidden';
    }
  }, []);

  useEffect(() => {
    if (isLoaded) {
      document.body.style.overflow = '';
    }
  }, [isLoaded]);

  return (
    <>
      <Preloader isLoaded={isLoaded}/>

      <Helmet>
        <title>Вакансии</title>
        <meta name="description" content="Nested component"/>
      </Helmet>

      <ModalVacancy
        vacancyId={vacancyId}
        modalEl={modalEl}
        isOpenModal={isOpenModal}
        setIsOpenModal={setIsOpenModal}
        isSuccessModal={isSuccessModal}
        setIsSuccessModal={setIsSuccessModal}
      />
      <section className="vacancy-promo">
        <div className="container">
          <div className="header__wrapper">
            <Header color={isMobile ? 'black' : 'white'} isBreadCrumbs={true} isBlackPromo={true}/>

          </div>

          <VacancyPromo/>
        </div>
      </section>

      <div className="container">
        <VacancyWork/>
        <VacancyList setVacancyId={setVacancyId} modalEl={modalEl} setIsOpenModal={setIsOpenModal}
                     dataVacancy={dataVacancy}/>
      </div>

      <CircleToTop/>

      <Footer/>
    </>
  )
}

export default Vacancy
