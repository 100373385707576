import React, {useState} from 'react'

const AsideItem = ({itemMenu, activeMenuItem, setActiveMenuItem}) => {
  const [visibleMenu, setVisibleMenu] = useState(false);

  return (
    <li
      key={itemMenu._id}
      className={`
      aside-menu__item 
      ${(visibleMenu && !!itemMenu.subcategories) ? 'minus' : ''}
      ${!itemMenu.subcategories ? 'last' : ''}
      `}
    >
      <h6
        className="aside-menu__item-title"
        onClick={() => {
          setVisibleMenu(!visibleMenu);
          if (!itemMenu.subcategories) {
            setActiveMenuItem(itemMenu._id);
          }
        }}
      >{itemMenu.title}</h6>
      <div
        className="aside-menu__products"
        style={{height: (visibleMenu && !!itemMenu.subcategories) ? (itemMenu.subcategories.length * 35) : ''}}
      >
        {
          !!itemMenu.subcategories && (
            itemMenu.subcategories.map(item => (
              <button
                key={item._id}
                className={`aside-menu__products-item ${item._id === activeMenuItem ? 'active' : ''}`}
                onClick={() => {
                  setActiveMenuItem(item._id);
                }}
              >{item.title}</button>
            ))
          )
        }
      </div>
    </li>
  )
}

export default AsideItem
