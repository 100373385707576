import React, {useContext, useEffect, useState} from "react";


const WidthContext = React.createContext();
export const useWidthContext = () => {
  return useContext(WidthContext);
}

export const WidthProvider = ({children}) => {
  const [isTablet, setIsTablet] = useState(false); //window.innerWidth <= 1200
  const [isMobile, setIsMobile] = useState(false); //window.innerWidth <= 500;

  useEffect(() => {
    if (window.innerWidth <= 1200) setIsTablet(true);
    if (window.innerWidth <= 500) setIsMobile(true);

    window.addEventListener("resize", e => {
      if (e.target.innerWidth <= 1200) {
        setIsTablet(true);
        if (e.target.innerWidth <= 500) {
          setIsMobile(true);
        } else setIsMobile(false)
      } else {
        setIsTablet(false);
      }
    })
  }, []);

  return (
    <WidthContext.Provider value={{isTablet, isMobile}}>
      {children}
    </WidthContext.Provider>
  )
}