export const baseUrlReal = process.env.REACT_APP_BASE_URL || "";

export const arrow = `
  <svg width="29" height="8" viewBox="0 0 29 8" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M0.646446 3.64644C0.451185 3.84171 0.451185 4.15829 0.646446 4.35355L3.82843 7.53553C4.02369 7.73079 4.34027 7.73079 4.53553 7.53553C4.7308 7.34027 4.7308 7.02369 4.53553 6.82842L1.70711 4L4.53553 1.17157C4.7308 0.976309 4.7308 0.659726 4.53553 0.464464C4.34027 0.269202 4.02369 0.269202 3.82843 0.464464L0.646446 3.64644ZM29 3.5L1 3.5L1 4.5L29 4.5L29 3.5Z" fill="white"/>
  </svg>
`;
export const arrowBlack = `
  <svg width="29" height="8" viewBox="0 0 29 8" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M0.646446 3.64644C0.451185 3.84171 0.451185 4.15829 0.646446 4.35355L3.82843 7.53553C4.02369 7.73079 4.34027 7.73079 4.53553 7.53553C4.7308 7.34027 4.7308 7.02369 4.53553 6.82842L1.70711 4L4.53553 1.17157C4.7308 0.976309 4.7308 0.659726 4.53553 0.464464C4.34027 0.269202 4.02369 0.269202 3.82843 0.464464L0.646446 3.64644ZM29 3.5L1 3.5L1 4.5L29 4.5L29 3.5Z" fill="black"/>
  </svg>
`;
export const isTablet = window.innerWidth <= 1200;
export const isMobile = window.innerWidth <= 500;

export const initNav = (swiper) => {
  swiper.navigation.prevEl.innerHTML = arrow;
  swiper.navigation.nextEl.innerHTML = arrow;
};
export const initNavBlack = (swiper) => {
  swiper.navigation.prevEl.innerHTML = arrowBlack;
  swiper.navigation.nextEl.innerHTML = arrowBlack;
};
