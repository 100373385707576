import React, { useEffect, useState } from 'react'
import {Swiper, SwiperSlide} from 'swiper/react';
import SwiperCore, {Navigation} from 'swiper';

import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';

import "./ModalColorCloth.scss";
import {initNavBlack} from "../../constants";

const ModalColorCloth = ({isOpenModal, setIsOpenModal, dataSlider}) => {
  const [activeImg, setActiveImg] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  SwiperCore.use([Navigation]);

  useEffect(() => {
    if (isOpenModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [isOpenModal]);

  const handleClickModal = (img) => {
    setActiveImg(img);
    setIsOpen(true);
  }

  const handleCloseDetail = () => {
    setIsOpen(false);
    setActiveImg("");
  }

  return (
    <>
      <div
        className={`overlay ${isOpenModal ? 'active' : ''}`}
        onClick={() => setIsOpenModal(false)}
      />
      <div className={`modal-color-cloth ${isOpenModal ? 'active' : ''}`}>
        <button
          className="modal-color-cloth__close"
          onClick={() => setIsOpenModal(false)}
        />

        <div className="modal-color-cloth__cloth">
          <img
            src={dataSlider.images}
            alt=""
            className="modal-color-cloth__cloth-img"
            onClick={() => handleClickModal(dataSlider?.images)}
          />
          <div className="modal-color-cloth__cloth-title">{dataSlider.value}</div>
        </div>


        {
          !!dataSlider.slider && dataSlider.slider.length > 0 && (
            <>
              <div className="modal-color-cloth__slider-title">Примеры</div>
              <Swiper
                className="modal-color-cloth__slider"
                spaceBetween={50}
                onInit={(swiper) => dataSlider.slider.length !== 0 ? swiper.update() : ''}
                onSwiper={(swiper) => dataSlider.slider.length > 1 && initNavBlack(swiper) }
                navigation={dataSlider.slider.length > 1}
              >
                {
                  dataSlider.slider.map((el, index) => (
                    <SwiperSlide key={`${el}_${index}`} className="modal-color-cloth__slider-item">
                      <img
                        src={el}
                        alt=""
                        className="modal-color-cloth__slider-img"
                        onClick={() => handleClickModal(el)}
                      />
                    </SwiperSlide>
                  ))
                }
              </Swiper>
            </>

          )
        }
      </div>

      {isOpen &&
      <>
        <div
          className={`overlay overlay--img active`}
          onClick={handleCloseDetail}
        />
        <div className="modal__img-detail">
          <button
            type="button"
            className="modal__close"
            onClick={handleCloseDetail}
          />
          <img src={activeImg} alt=""/>
        </div>
      </>}
    </>
  );
};

export default ModalColorCloth;