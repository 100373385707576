import React, {createContext, useContext, useState} from "react";


const StaticContext = createContext();

export const useStaticPage = () => useContext(StaticContext);

export const StaticProvider = ({children}) => {
  const [staticPages, setStaticPages] = useState([]);

  return (
    <StaticContext.Provider value={[staticPages, setStaticPages]}>
      {children}
    </StaticContext.Provider>
  )
}
