import React, {useContext, useEffect, useState} from 'react'
import {cartApi} from '../api/api';
import Header from '../components/Header/Header';
import CartHeader from '../components/Сart/CartHeader/CartHeader';
import CartList from '../components/Сart/CartList/CartList';
import CartRegisterOrder from '../components/Сart/CartRegisterOrder/CartRegisterOrder';
import SliderAll from '../components/SliderAll/SliderAll';
import Footer from '../components/Footer/Footer';
import SuccessOrder from "../components/Сart/SuccessOrder/SuccessOrder";
import {CartContext} from "../context";
import {Link} from "react-router-dom";

import '../components/Сart/Cart.scss';
import {resetState} from "../reducers/cartReducer";
import Preloader from "../components/Preloader/Preloader";
import Helmet from "react-helmet";
import {numberWithSpaces} from "../Functions";

const Cart = () => {
  const [dataDetailRecomendation, setDataDetailRecomendation] = useState([]);
  const [stage, setStage] = useState(0);
  const [state, dispatch] = useContext(CartContext);
  const [isFinallyStage, setIsFinallyStage] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);


  useEffect(() => {
    if (!isLoaded) {
      document.body.style.overflow = 'hidden';
    }

    cartApi.getRecomendation()
      .then(data => {
        setDataDetailRecomendation(data);
        setIsLoaded(true);
        setTimeout(() => {
          window.scrollTo({
            top: 0,
          })
        }, 800)
      });
  }, [])

  const updateStage = (value) => {
    setStage(value);
  }


  const itemsCartObj = {
    items: [...state.cartItems.map(el => {
      return {
        id: el._id,
        quantity: el.quantity,
        properties: !!el.properties && [
          ...Object.values(el.properties).map(prop => {
            let id;
            if (!!prop.items) {
              prop.items.forEach(el => {
                if (el.selected) {
                  id = el.id;
                }
              })
              return {
                propId: prop.id,
                selectedPropId: id
              }
            } else {
              return null;
            }
          }).filter(el => !!el)
        ],
      }
    })],
    ...state.cartForm,
    fuserId: localStorage.getItem('fUserId'),
  }

  useEffect(() => {
    if (Object.values(state.cartForm).length > 0) {
      cartApi.postCartItems(JSON.stringify(itemsCartObj, null, 2))
        .then(() => {
          localStorage.setItem('cartItems', []);
          localStorage.setItem('totalPrice', 0);
          dispatch(resetState())
        });
    }
  }, [state.cartForm]);

  useEffect(() => {
    if (isLoaded) {
      document.body.style.overflow = '';
    }
  }, [isLoaded]);

  return (
    <>
      <Preloader isLoaded={isLoaded}/>

      <Helmet>
        <title>Корзина</title>
        <meta name="description" content="Nested component" />
      </Helmet>

      <div
        className='cart'
        style={{overflow: stage === 0 ? 'hidden' : ''}}
      >
        <div className="container">
          <Header color={'black'}/>
          {
            (state.cartItems.length === 0 && state.totalPrice === 0 && !isFinallyStage) ? (
              <div className="cart-empty__text">
                <h2 className="cart-empty__title">Корзина пуста</h2>
                <div className="cart-empty__subtitle">Зайдите в наш каталог и выберите товар</div>

                <Link to="/production" className="cart-empty__link">Каталог</Link>
              </div>
            ) : (
              <>
                <CartHeader stage={stage} updateStage={updateStage}/>
                {
                  stage === 0 ?
                    (
                      <div className="cart__orders">


                        <CartList/>


                        <div className="cart__price">
                          <div className="cart__price-total">{`от ${numberWithSpaces(state.totalPrice)} ₽`}</div>
                          <button
                            className="cart__price-order"
                            onClick={() => {
                              setStage(1);
                              window.scrollTo({
                                top: 0,
                                behavior: "smooth",
                              })
                            }}
                          >Оформить заказ
                          </button>
                        </div>
                      </div>
                    ) : stage === 1 ? (
                      <CartRegisterOrder updateStage={setStage} setIsFinallyStage={setIsFinallyStage}/>
                    ) : stage === 2 ? (
                      <SuccessOrder/>
                    ) : ''
                }
              </>
            )
          }
          {
            stage === 0 && (
              <div className="recommend-goods">
                <h3 className="recommend-goods__title">Рекомендации</h3>
                <SliderAll
                  dataNewsProduct={dataDetailRecomendation}
                  isDetail={true}
                  setIsLoaded={setIsLoaded}
                />
              </div>
            )
          }
        </div>
      </div>


      <Footer/>
    </>
  )
}

export default Cart
