import React from 'react'

import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';

import './DetailCardPromo.scss';


const DetailCardPromo = ({dataDetailPrev}) => {

  return (
    <>
      {
        !!dataDetailPrev.images && (
          <div
            className='goods-card__slider'
          >
            <div className="goods-card__slider-item">
              <img src={dataDetailPrev.images[0]} alt={dataDetailPrev.title} className="goods-card__slider-item-img"/>
              <div className="goods-card__slider-item-name">{dataDetailPrev.title}</div>
            </div>
          </div>
        )

      }
    </>
  )
}

export default DetailCardPromo
