import React, { useEffect } from "react";

import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import Shops from "./../components/Shops/Shops";

import "../components/Dealers/Dealers.scss";
import Helmet from "react-helmet";

const Dealers = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="dealers">
      <Helmet>
        <title>Салоны</title>
        <meta name="description" content="Nested component" />
      </Helmet>

      <div className="container">
        <Header color={"black"} />

        <h2 className="dealers__title">Салоны</h2>

        <Shops />
      </div>
      <Footer />
    </div>
  );
};

export default Dealers;
