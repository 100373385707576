import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router} from 'react-router-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {debugContextDevtool} from 'react-context-devtool';
import CartProvider from "./CartProvider";
import {StaticProvider} from "./staticContext";

const container = document.getElementById("root");

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <CartProvider>
        <StaticProvider>
          <App/>
        </StaticProvider>
      </CartProvider>
    </Router>
  </React.StrictMode>,
  container
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
debugContextDevtool(container, {
  disable: process.env.NODE_ENV === "production"
});
