import React from 'react';

import './SocialLinks.scss';

const SocialLinks = () => {
  return (
    <div className="social-links">
      <a href="https://vk.com/evanty_fabrika" target="_blank" rel="noopener noreferrer" className="social-links__item">
        <h3 className="social-links__item-title">VK</h3>
        <div className="social-links__item-text">
          <p>Получайте свежий контент о дизайне интерьера и качественной мебели</p>
        </div>
      </a>
      <a href="https://t.me/evanty_fabrika" target="_blank" rel="noopener noreferrer" className="social-links__item">
        <h3 className="social-links__item-title">Telegram</h3>
        <div className="social-links__item-text">
          <p>Подписывайтесь на наш канал, чтобы узнавать свежие новости компании</p>
        </div>
      </a>
    </div>
  )
}

export default SocialLinks
